<template>
  <div style="width: 410px;">
      <div class="float-left mr-2" v-for="feature in features" :key="feature.id">
          <a href="javascript: void(0)" @click="featureClick(feature)">
            <img :src="feature.member.avatar + '.png?map=1'" width="50" height="50" />
          </a>
      </div>
  </div>
</template>
<script>
export default {
  name: "DuplicateLocationPopup",
  props: {
    features: {
      type: Array,
      required: true,
    },
  },
  methods: {
      featureClick(feature) {
          this.$emit('featureClick', feature);
      }
  },
  created() {

  },
};
</script>
