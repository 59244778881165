<template>
  <b-container class="px-0 grid-cards">
    <div v-if="loading" class="row justify-content-center ">
        <loading :loading="true"/>
    </div>
    <div v-else-if="list.length === 0" class="row justify-content-center ">
      <div class="col-12 col-lg-12">
        <div class="card no-match-list" v-if="userRoleType === 'BUYER'">
          <div class="card-body py-5 text-center ">
            <div
              style="
                background: #f8f8f8;
                border-radius: 10px;
                padding: 50px 73px;
                text-align: left;
                
              "
            >
              <h4>{{$t("mw.nomatch-buyer.title")}}</h4>
              <hr />
              <p>
                {{$t("mw.nomatch-buyer.p1")}}
              </p>
              <h5>{{$t("mw.nomatch-buyer.q?")}}</h5>
              <ul>
                <li>{{$t("mw.nomatch-buyer.p2")}}</li>
                <li>
                  {{$t("mw.nomatch-buyer.p3")}}
                </li>
                <li>
                  {{$t("mw.nomatch-buyer.p4")}}
                </li>
              </ul>

              <p class="mb-5">
               {{$t("mw.nomatch-buyer.p5")}}
              </p>

              <button
                @click="openAddItemModal()"
                v-if="userRoleType === 'BUYER'"
                class="btn btn-primary btn-sm"
                style="padding: 5px 20px !important"
              >
                {{$t("buttons.cr")}}
              </button>
            </div>
          </div>
        </div>

        <div class="card no-match-list" v-if="userRoleType === 'SELLER'">
          <div class="card-body py-5 text-center">
            <div
              style="
                background: #f8f8f8;
                border-radius: 1px;
                padding: 50px 73px;
                text-align: left;
                
              "
            >
              <h4>{{$t("mw.nomatch-seller.title")}}</h4>
              <hr />
              <p>
               {{$t("mw.nomatch-seller.p1")}}
              </p>
              <h5>{{$t("mw.nomatch-seller.q?")}}</h5>
              <ul>
                <li>{{$t("mw.nomatch-seller.p2")}}</li>
                <li>
                 {{$t("mw.nomatch-seller.p3")}}
                </li>
                <li>
                 {{$t("mw.nomatch-seller.p4")}}
                </li>
              </ul>

              <p class="mb-5">
               {{$t("mw.nomatch-seller.p5")}}
              </p>

              <button
                @click="openAddItemModal()"
                v-if="userRoleType === 'SELLER'"
                class="btn btn-primary btn-sm"
              >
               {{$t("buttons.cq")}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-row v-else >
      <b-col
        style="margin-bottom: 30px !important"
        cols="3"
        v-for="(item, index) in list"
        :key="item.id"
      >
        <div @click="openModal(index)" v-if="userRoleType === 'SELLER'">
          <b-row>
            <b-col cols="3" class="" >
              <div class="buyer-card-grid">
                <div class="match-rate-bar">
                  <b-row>
                    <b-col cols="6" class="text-left">
                      <h6>{{$t("mw.general.matchrate")}}</h6>
                    </b-col>
                    <b-col cols="6" class="text-right">
                      <h5>% {{ item._score.toFixed(1) }}</h5>
                    </b-col>
                  </b-row>
                </div>

                <b-row>
                  <b-col cols="12">
                    <div class="buyer-card-content">
                      <h1>{{ findSubItemByLang(item.contents).name }}</h1>
                      <p>
                        {{ findSubItemByLang(item.contents).description }} 
                      </p>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="buyer-card-bottom-section">
                  <b-col cols="3" class="pl-4">
                    <h3>{{$t("mw.general.unit")}}</h3>
                    <h3>{{$t("mw.general.demand")}}</h3>
                  </b-col>
                  <b-col cols="9">
                    <p>{{ item.unit.shortname }}</p>
                    <p style="padding-top: 7px">{{ numberFixer(item.demand) }}</p>
                  </b-col>
                </b-row>

                <b-row class="buyer-industry-icon pl-4">
                  <b-col
                    cols="2"
                    class="text-center"
                    style="
                      background: #fafbfc;
                      border-radius: 6px;
                      margin: auto;
                      padding-top: 10px;
                      padding-bottom: 10px;
                    "
                  >
                    <industry-icon
                      :color="'primary'"
                      :name="item.industry.icon"
                    />
                  </b-col>
                  <b-col cols="10" class="pt-2">
                    <h4>{{ item.industry.name }}</h4>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="seller-card-bottom-fixed">
                <b-row>
                  <b-col cols="3" class="text-right pr-0">
                    <b-avatar
                      :src="getAvatar(item.member.avatar, 92)"
                      size="52"
                    ></b-avatar>
                  </b-col>
                  <b-col cols="9" class="text-left pt-1">
                    <h3>
                      {{ shorter(item.member.name, item.member.surname) }}
                      <img
                        v-if="$_.get(item, 'member.verification.icon')"
                        :src="$_.get(item, 'member.verification.icon')"
                        class="img-fluid verification-icon-cards"
                        alt=""
                      />
                    </h3>
                    <p>{{ item.member.company.name }}</p>
                  </b-col>
                </b-row>

                <div v-if="$_.get(item, 'member.group.icon')" class="">
                  <img
                    :src="$_.get(item, 'member.group.icon')"
                    class="img-fluid seller-star-icon"
                    alt=""
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <div @click="openModal(index)" v-if="userRoleType === 'BUYER'">
          <b-container>
            <b-row class="">
              <b-col cols="3" class="">
                <div class="seller-card-grid">
                  <div class="match-rate-bar">
                    <b-row>
                      <b-col cols="6" class="text-left">
                        <h6>{{$t("mw.general.matchrate")}}</h6>
                      </b-col>
                      <b-col cols="6" class="text-right">
                        <h5>% {{ item._score.toFixed(1) }}</h5>
                      </b-col>
                    </b-row>
                  </div>

                  <b-row>
                    <b-col cols="12">
                      <div class="slider-items">
                        <b-carousel
                          id="carouselSeller"
                          v-model="item.slide"
                          :interval="4000"
                          controls
                          indicators
                          background="#eff2f6"
                          img-width="100%"
                          img-height="100%"
                          style="text-shadow: 1px 1px 2px #333; margin: auto"
                          @sliding-start="onSlideStart"
                          @sliding-end="onSlideEnd"
                        >
                          <!-- Text slides with image -->
                          <b-carousel-slide
                            v-for="(img, index) in item.images"
                            :key="index"
                            class="card-view-carousel-gridview-img"
                            :img-src="
                              CDN_IMAGE_PREFIX +
                              img +
                              '?size=autox180'
                            "
                          ></b-carousel-slide>
                        </b-carousel>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="10" class="text-left seller-contents">
                      <h1> <span style="text-transform:capitalize;">{{ findSubItemByLang(item.contents).name }}</span></h1>
                      <h4>{{$t("mw.general.id")}}{{ item.product_id }}</h4>
                       <p style="font-size:14px; margin-top:10px widht:30px; -webkit-line-clamp: 1;">
                         {{ item.currency.symbol }} {{ item.min_price }} -  {{ item.currency.symbol }}  {{ item.max_price }} <span>/ {{ item.unit.name }}</span>
                      </p>
                    </b-col>
                    <b-col cols="2" class="text-right industry-cards">
                      <industry-icon
                        :color="'red'"
                        :name="item.industry.icon"
                      />
                      
                    </b-col>
                    <b-col cols="12">
                      <p>
                        {{ findSubItemByLang(item.contents).description }}
                      </p>
                    </b-col>
                  </b-row>

                  <!-- <b-row class="seller-card-bottom-section">
                    <b-col cols="3">
                      <h3>{{$t("mw.general.price")}}</h3>
                      <h3>{{$t("mw.general.moq")}}</h3> 
                      <h3>{{$t("mw.general.supply")}}</h3>
                    </b-col>
                    <b-col cols="9">
                      <p>
                         {{ item.currency.symbol }} {{ item.min_price }} -  {{ item.currency.symbol }}  {{ item.max_price }} <span>/ {{ item.unit.shortname }}</span>
                      </p>
                      <p>
                        {{ item.moq }} <span>{{ item.unit.shortname }}</span>
                       
                      </p>
                      <p>{{ item.stock }} <span> {{ item.unit.shortname }} </span><span> / {{$t("mw.general.monthly")}}</span></p>
                    </b-col>
                  </b-row> -->
                </div>
              </b-col>
               <b-col cols="12">
                <div class="seller-card-bottom-fixed">
                  <b-row>
                    <b-col cols="3" class="text-right pr-0">
                      <b-avatar
                        :src="getAvatar(item.member.avatar, 52)"
                        size="52"
                      ></b-avatar>
                    </b-col>
                    <b-col cols="9" class="text-left pt-1">
                      <h3>
                          {{ shorter(item.member.name, item.member.surname) }}
                        <img
                          v-if="$_.get(item, 'member.verification.icon')"
                          :src="$_.get(item, 'member.verification.icon')"
                          class="img-fluid verification-icon-cards"
                          alt=""
                        />
                      </h3>
                      <p>{{ item.member.company.name }}</p>
                    </b-col>
            </b-row>
           

                  <div v-if="$_.get(item, 'member.group.icon')" class="">
                    <img
                      :src="$_.get(item, 'member.group.icon')"
                      class="img-fluid seller-star-icon"
                      alt=""
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-col>
    </b-row>

    <!-- <div class="row" v-if="loading">
      <div class="col-12">
        <loading :loading="loading"></loading>
      </div>
    </div> -->
    <!--<div>
      <div v-if="list.length > 0" class="col-12">
        <div class="d-flex justify-content-end">
          <button
            :disabled="isDisablePrev"
            @click="prevPage"
            class="btn btn-primary btn-sm my-4 mx-3"
          >
            <v-icon icon="chevron-left"></v-icon>
          </button>
          <button
            :disabled="isDisableNext"
            @click="nextPage"
            class="btn btn-primary btn-sm my-4"
          >
            <v-icon icon="chevron-right"></v-icon>
          </button>
        </div>
      </div> 
    </div> -->
    <b-row class="gridview-pagination" v-if="userRoleType === 'BUYER'">
      <b-col cols="12">
        <b-pagination
          v-if="list.length > 0 && loading === false"
          class="mt-4"
          align="center"
          v-model="gridCurrentPage"
          pills
          :total-rows="gridTotal"
          :per-page="gridItemPerPage"
          @change="gridPageChange()"
        ></b-pagination>
      </b-col>
    </b-row>

    <b-row class="gridview-pagination" v-if="userRoleType === 'SELLER'">
      <b-col cols="12">
        <b-pagination
          v-if="list.length > 0 && loading === false"
          class="mt-4"
          align="center"
          v-model="gridCurrentPageSeller"
          pills
          :total-rows="gridTotal"
          :per-page="gridItemPerPage"
          @change="gridPageChangeSeller()"
        ></b-pagination>
      </b-col>
    </b-row>

    <div v-if="content">
      <popup
        @follow="followUser"
        ref="popupItem"
        @next="nextPopup"
        @prev="prevPopup"
        @close="closePopup"
        :item.sync="selectItem"
      ></popup>
    </div>
  </b-container>
</template>

<script>
import loading from "@/components/global/loading/index";
import buyerMatchList from "../../../../graphql/me/matching/buyer/List.gql";
import sellerMatchList from "@/graphql/me/matching/seller/list.graphql";
import popup from "@/components/app/matching/component/MapPopupComponent";
export default {
  components: {
    loading,
    popup,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      popupIndex: 0,
      selectItem: null,
      gridCurrentPage: 1,
      gridCurrentPageSeller: 1,
      gridTotal: 0,
      gridItemPerPage: 20,
      totalData: null,
      cursors: [],
      test: "deneme",
      list: [],
      content: false,
      loading: true,
      limit: 20,
      cursor: null,
      sellerFilterList: {
        limit: 20,
        page: 1,
        filter: {
          distance: 0,
        },
        orders: [
          { field: "_score", sort: "DESC" },
          { field: "id", sort: "DESC" },
        ],
      },
      cardFilterBuyer: {
        limit: 20,
        page: 1,
        filter: {},
        orders: [
          { field: "_score", sort: "DESC" },
          { field: "id", sort: "DESC" },
        ],
      },
      isLoading: false,
    };
  },
  watch: {
    popupIndex() {
      this.changePopupItem();
    },
    content(val) {
      if (val) {
        this.changePopupItem();
      }
    },
  },
  computed: {
    totalPage() {
      let totalPage = Math.ceil(this.totalData / this.limit);
      return totalPage;
    },
    currentPage() {
        if (this.userRoleType === "BUYER") {
          return this.gridCurrentPage;
        }
        
        if (this.userRoleType === "SELLER") {
            return this.gridCurrentPageSeller;
        }
    },
    data: function () {
      return this.initialState();
    },
    isDisablePrev() {
      if (this.currentPage <= 1) {
        return true;
      }
      if (this.content) {
        this.$nextTick(() => {
          this.$refs.popupItem._data.disabledPrev = false;
        });
      }
      return false;
    },
    isDisableNext() {
      if (this.currentPage >= this.totalPage) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async gridPageChange() {
      await this.$nextTick();
      this.cardFilterBuyer.page = this.gridCurrentPage;
      await this.getList();
      await this.$nextTick();
      this.appScrollTop();
      return true;
    },
    async gridPageChangeSeller() {
      await this.$nextTick();
      this.sellerFilterList.page = this.gridCurrentPageSeller;
      await this.getListBySeller();
      await this.$nextTick();
      this.appScrollTop();
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    followUser(val) {
      let id = val.id;
      let status = val.status;
      for (let item of this.list) {
        if (item.member.id === id) {
          item.member.following = status;
        }
      }
    },
    changePopupItem() {
      if (this.content) {
        if (
          this.currentPage >= this.totalPage &&
          this.popupIndex + 1 === this.list.length
        ) {
          this.$nextTick(() => {
            this.$refs.popupItem._data.disabledNext = true;
          });
        } else {
          this.$nextTick(() => {
            this.$refs.popupItem._data.disabledNext = false;
          });
        }

        if (this.currentPage <= 1 && this.popupIndex === 0) {
          this.$nextTick(() => {
            this.$refs.popupItem._data.disabledPrev = true;
          });
        } else {
          this.$nextTick(() => {
            this.$refs.popupItem._data.disabledPrev = false;
          });
        }
      }
    },
    async nextPopup(index) {
      this.$nextTick(() => {
        this.$refs.popupItem._data.isLoading = true;
      });
      let length = this.list.length;
      if (index + 1 === length) {
        await this.nextPage();
        this.$nextTick(() => {
          this.$refs.popupItem._data.itemIndex = 0;
        });

        this.popupIndex = 0;
        this.$nextTick(() => {
           this.$refs.popupItem._data.selectItem = this.selectItem = this.list[0];
        });
        this.changePopupItem();
        return this.$nextTick(() => {
          this.$refs.popupItem._data.isLoading = false;
        });
      }

      this.$nextTick(() => {
        this.selectItem = this.list[++index];
      });
      this.$nextTick(() => {
        this.$refs.popupItem._data.itemIndex = index;
        this.$refs.popupItem.selectItem = this.selectItem;
      });
      this.$nextTick(() => {
        this.popupIndex = index;
      });
      this.$nextTick(() => {
        this.changePopupItem();
      });
      this.$nextTick(() => {
        this.$refs.popupItem._data.isLoading = false;
      });
    },
    async prevPopup(index) {
      this.$refs.popupItem._data.isLoading = true;

      if (index === 0 && this.currentPage !== 1) {
        await this.prevPage();
        await this.$nextTick();
        let length = this.list.length;
        this.$refs.popupItem._data.selectItem = this.selectItem = this.list[length - 1];
        this.$refs.popupItem._data.itemIndex = length - 1;
        this.popupIndex = length - 1;
        this.changePopupItem();
        return (this.$refs.popupItem._data.isLoading = false);
      }
      this.selectItem = this.list[index];
      this.$nextTick(() => {
        this.$refs.popupItem.selectItem = this.selectItem;
      });
      this.$refs.popupItem._data.itemIndex = index;
      this.popupIndex = index;
      this.changePopupItem();
      return (this.$refs.popupItem._data.isLoading = false);
    },
    async nextPage() {
      if (this.userRoleType === "BUYER") {
          this.gridCurrentPage++;
          await this.gridPageChange();
      }
      if (this.userRoleType === "SELLER") {
        this.gridCurrentPageSeller++;
        await this.gridPageChangeSeller();
      }
    },
    async prevPage() {
      if (this.userRoleType === "BUYER") {
          this.gridCurrentPage--;
          await this.gridPageChange();
      }
      if (this.userRoleType === "SELLER") {
        this.gridCurrentPageSeller--;
        await this.gridPageChangeSeller();
      }
    },
    initialState() {
      return {
        selectItem: null,
        totalData: null,
        cursors: [],
        currentPage: 1,
        test: "deneme",
        list: [],
        content: false,
        loading: true,
        limit: 20,
        cursor: null,
        sellerFilterList: {
          limit: 20,
          page: 1,
          filter: {},
          orders: [
            { field: "_score", sort: "DESC" },
            { field: "id", sort: "DESC" },
          ],
        },
        cardFilterBuyer: {
          limit: 20,
          page: 1,
          filter: {
              distance: null
          },
          orders: [
            { field: "_score", sort: "DESC" },
            { field: "id", sort: "DESC" },
          ],
        },
        isLoading: false,
      };
    },
    async resetComponent() {
      Object.assign(this.$data, this.initialState());
      if (this.userRoleType === "BUYER") {
        await this.getList();
        this.loading = false;
      }
      if (this.userRoleType === "SELLER") {
        await this.getListBySeller();
        this.loading = false;
      }
    },
    closePopup() {
      this.content = false;
    },
    openModal(index) {
      this.content = true;
      this.selectItem = this.list[index];
      this.popupIndex = index;
      this.$nextTick(() => {
        this.$refs.popupItem._data.itemIndex = index;
      });

      //this.$bvModal.show("modal-1");
    },
    async getList() {
      try {
        this.loading = true;
        let data = await this.$apollo.query(buyerMatchList, {
          limit: this.cardFilterBuyer.limit,
          filters: this.cardFilterBuyer.filter,
          orders: this.cardFilterBuyer.orders,
          page: this.cardFilterBuyer.page,
        });
        let response = data("**.list");
        this.gridTotal = data("**.total_item");
        console.log(response);
        //this.cursor = response.next_cursor;
        this.totalData = response.total_item || 0;
        /*if (!this.cursors.includes(response.next_cursor)) {
          this.cursors.push(response.next_cursor);
        } */
        this.list = response.list;
        this.$emit("totalCount", response.total_member);
      } catch (e) {
        console.log(e);
      } finally {
          this.loading = false;
      }
    },
    async getListBySeller(par) {
        try {
            this.loading = true;
            let data = await this.$apollo.query(sellerMatchList, {
                limit: this.sellerFilterList.limit,
                filters: this.sellerFilterList.filter,
                orders: this.sellerFilterList.orders,
                page: this.sellerFilterList.page,
            });
            let response = data("**.list");
            this.gridTotal = data("**.total_item");
            this.totalData = response.total_item || 0;
            this.list = response.list;
            this.$emit("totalCount", response.total_member);
        } catch (error) {
            console.log(error);    
        } finally {
            this.loading = false;
        }
    },
    testMethod() {
      console.log(this.test);
    },
  },
  async created() {
    this.loading = true;
    let userInfo = this.GET_USER_INFO;
    this.userType = userInfo.type;
    this.loading = false;
  },
};
</script>
<style scoped>
#modal-1___BV_modal_outer_ {
  pointer-events: none !important;
}
</style>
