<template>
  <div class="container list-product zoom" style="margin-top: -40px">
    <img :src="getIndustryIcon('technology')" alt="" />
    <div v-if="loading" class="row justify-content-center">
        <loading :loading="true"/>
    </div>
    <div v-if="!loading && list.length === 0" class="row justify-content-center">
      <div class="col-12 col-lg-12">
        <div class="card no-match-list" v-if="userRoleType === 'BUYER'">
          <div class="card-body py-5 text-center">
            <div
              style="
                background: #f8f8f8;
                border-radius: 1px;
                padding: 50px 73px;
                text-align: left;
              "
            >
                  <h4>{{$t("mw.nomatch-buyer.title")}}</h4>
              <hr />
              <p>
                {{$t("mw.nomatch-buyer.p1")}}
              </p>
              <h5>{{$t("mw.nomatch-buyer.q?")}}</h5>
              <ul>
                <li>{{$t("mw.nomatch-buyer.p2")}}</li>
                <li>
                  {{$t("mw.nomatch-buyer.p3")}}
                </li>
                <li>
                  {{$t("mw.nomatch-buyer.p4")}}
                </li>
              </ul>

              <p class="mb-5">
               {{$t("mw.nomatch-buyer.p5")}}
              </p>

              <button
                @click="openAddItemModal"
                v-if="userRoleType === 'BUYER'"
                class="btn btn-primary btn-sm"
                style="padding: 5px 20px !important"
              >
                {{$t("buttons.cr")}}
              </button>
            </div>
          </div>
        </div>

        <div class="card no-match-list" v-if="userRoleType === 'SELLER'">
          <div class="card-body py-5 text-center">
            <div
              style="
                background: #f8f8f8;
                border-radius: 1px;
                padding: 50px 73px;
                text-align: left;
              "
            >
                 <h4>{{$t("mw.nomatch-seller.title")}}</h4>
              <hr />
              <p>
               {{$t("mw.nomatch-seller.p1")}}
              </p>
              <h5>{{$t("mw.nomatch-seller.q?")}}</h5>
              <ul>
                <li>{{$t("mw.nomatch-seller.p2")}}</li>
                <li>
                 {{$t("mw.nomatch-seller.p3")}}
                </li>
                <li>
                 {{$t("mw.nomatch-seller.p4")}}
                </li>
              </ul>

              <p class="mb-5">
               {{$t("mw.nomatch-seller.p5")}}
              </p>

              <button
                @click="openAddItemModal"
                v-if="userRoleType === 'SELLER'"
                class="btn btn-primary btn-sm"
              >
               {{$t("buttons.cq")}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
     v-if="!loading && list.length > 0 && userRoleType === 'SELLER'"
      class="row no-gutters "
    >
      <div class="col-12">
        <div class="list-header d-flex w-100">
          <div class="col pn">
            <span>{{$t("mw.general.contact")}}</span>
          </div>
          <div class="col pn">
            <span>{{$t("mw.general.r-def")}}</span>
          </div>
           <div class="col un"  style="    display: flex;
    justify-content: center;
}">
            <span>{{$t("mw.general.demand")}}</span>
          </div>
          <div class="col un">
            <span>{{$t("mw.general.unit")}}</span>
          </div>
         
          <div class="col con">
            <span>{{$t("mw.general.category")}}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading && list.length > 0 && userRoleType === 'SELLER'"
      class="row no-gutters "
    >
      <div class="col-12">
        <div class="list-content">
          <div
            v-for="(item, index) in list"
            :key="item.id"
            class="item d-flex align-items-center"
            @click.prevent="openModal(index)"
          >
            <div class="col pn d-flex align-items-center">
              <div>
                <img
                  :src="getAvatar(item.member.avatar, 92)"
                  alt=""
                  width="92"
                  height="92"
                  class="customer-img"
                />
              </div>
              <div>
                <h3 class="product-name onepar">
                  {{ shorter(item.member.name,"") }} {{ item.member.surname }}
                </h3>
                <span class="product-id onepar"
                  >{{ item.member.title.name }}
                </span>
                <p class="p-0 m-0 customer-company onepar">
                  {{ item.member.company.name }}
                </p>
              </div>
            </div>
            <div class="col pn">
              <div>
                <h3 class="product-name onepar"
                    style="margin-bottom:10px !important"
                  
              >
                  {{ findSubItemByLang(item.contents).name }}
                </h3>
                <span class="product-id onepar"
                 style="font-size:14px !important">{{
                  findSubItemByLang(item.contents).description
                }}</span>
              </div>
            </div>
             <div class="col un" style="    display: flex;
    justify-content: center;
}">
              <span class="product-price">{{numberFixer(item.demand) }} </span>
            </div>
            <div class="col un ml-6">
              <span class="product-unit">{{ item.unit.name }}</span>
            </div>
           
            <div
              class="col d-flex con align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center">
                <div style="display: contents">
                  <industry-icon
                    :color="'primary'"
                    :name="item.industry.icon"
                    class="mr-2"
                  />
                  <span class="customer-name onepar">
                    {{ item.industry.name }}
                  </span>
                </div>
              </div>
              <div class="d-flex list-item-icon">
                <a @click.prevent="openModal(index)" href="#">
                  <v-icon icon="eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            :disabled="isDisablePrev"
            @click="prevPage"
            class="btn btn-primary btn-sm my-4 mx-3"
          >
            <v-icon icon="chevron-left"></v-icon>
          </button>
          <button
            :disabled="isDisableNext"
            @click="nextPage"
            class="btn btn-primary btn-sm my-4"
          >
            <v-icon icon="chevron-right"></v-icon>
          </button>
        </div>
      </div> -->
    </div>
    <div
      v-if="!loading && list.length > 0 && userRoleType === 'BUYER'"
      class="row no-gutters"
    >
      <div class="col-12">
        <div class="list-header d-flex w-100">
          <div class="col pn" style="margin-left:0px">
            <span>{{$t("mw.general.p-title-desc")}}</span>
          </div>
          <div class="col pl-0" style="margin-left:70px">
            <span>{{$t("mw.general.desc")}}</span>
          </div>
          <div class="col un" style="margin-left:80px">
            <span>{{$t("mw.general.ppu")}}</span>
          </div>
          <div class="col un">
            <span>{{$t("mw.general.moq")}}</span>
          </div>
          <div class="col con">
            <span>{{$t("mw.general.contact")}}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading && list.length > 0 && userRoleType === 'BUYER'"
      class="row no-gutters"
    >
      <div class="col-12">
        <div class="list-content">
          <div
            v-for="(item, index) in list"
            :key="item.id"
            class="item d-flex align-items-center"
            @click="openModal(index)"
          >
            <div class="col pn d-flex align-items-center">
              <div>
                <img
                  :src="
                    CDN_IMAGE_PREFIX +
                    item.images[0] +
                    '?size=50xauto'
                  "
                  width="50"
                  alt=""
                />
              </div>
              <div>
                <h3 class="product-name onepar">
                  {{ findSubItemByLang(item.contents).name }}
                </h3>
                <span class="product-id onepar"
                  >{{$t("mw.general.id")}}: {{ item.product_id }}</span
                >
              </div>
            </div>
            <div class="col pl-0">
              <span class="product-desc"
                >{{ findSubItemByLang(item.contents).description }}
              </span>
            </div>
            <div class="col un">
              <span class="product-price"
                >{{ item.currency.symbol }} {{ item.min_price }} - {{ item.currency.symbol }} {{ item.max_price }}</span
              ><span class="product-unit" style="color: #a7a7a7 !important">
                / {{ item.unit.shortname }}</span
              >
            </div>
            <div class="col un" style="font-size: 14px">{{ numberFixer(item.moq)}} 
              <span class="product-unit" style="color: #a7a7a7 !important">{{ item.unit.shortname }}</span>

              </div>
            <div
              class="col d-flex con align-items-center justify-content-between" style="margin-left:-80px"
            >
              <div class="d-flex align-items-center">
                <div>
                  <img
                    :src="getAvatar(item.member.avatar, 60)"
                    width="60"
                    height="60"
                    alt=""
                    class="customer-img"
                  />
                </div>
                <div style="
                width:150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;">
                  <h3 class="customer-name onepar" style="text-transform:capitalize;  ">
                    {{ item.member.name }} {{ item.member.surname }}
                  </h3>
                  <span class="customer-position onepar">
                    {{ item.member.title.name }}</span
                  >
                  <span class="customer-company onepar">{{
                    item.member.company.name
                  }}</span>
                </div>
              </div>
              <div class="d-flex list-item-icon">
                <a @click="openModal(index)" href="#" class="mr-4 pt-4">
                  <v-icon icon="eye" />
                </a>
                <b-dropdown
                  right
                  size="lg"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  class="messages-edit-list-icon dropdown-2-a"
                >
                  <template #button-content class="p-0">
                    <!--<v-icon :icon="['fas', 'ellipsis-v']"></v-icon> -->
                    <!-- <img
                      src="/img/open-card-footer-menu.svg"
                      class="img-fluid"
                      alt=""
                      style="width: 16px; height: 16px"
                    /> -->
                  </template>
                  <b-dropdown-item
                    href="#"
                    class="pl-3 mr-3"
                    style="padding: 0 important"
                  >
                    <img
                      src="/img/follow.svg"
                      style="width: 20px"
                      class="img-fluid"
                      alt=""
                    />
                    {{$t("buttons.follow")}}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="#"
                    class="pl-3 mr-3"
                    style="padding: 0 important"
                  >
                    <img
                      src="/img/message.svg"
                      style="width: 20px"
                      class="img-fluid"
                      alt=""
                    />
                  {{$t("buttons.chat")}}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            :disabled="isDisablePrev"
            @click="prevPage"
            class="btn btn-primary btn-sm m-4"
          >
            <v-icon icon="chevron-left"></v-icon>
          </button>
          <button
            :disabled="isDisableNext"
            @click="nextPage"
            class="btn btn-primary btn-sm m-4"
          >
            <v-icon icon="chevron-right"></v-icon>
          </button>
        </div>
      </div>-->
    </div>
    <b-row class="gridview-pagination" v-if="userRoleType === 'BUYER'">
      <b-col cols="12">
        <b-pagination
          v-if="list.length != 0 && loading === false"
          class="mt-4"
          align="center"
          v-model="gridCurrentPage"
          pills
          :total-rows="gridTotal"
          :per-page="gridItemPerPage"
          @change="gridPageChange()"
        ></b-pagination>
      </b-col>
    </b-row>

    <b-row class="gridview-pagination" v-if="userRoleType === 'SELLER'">
      <b-col cols="12">
        <b-pagination
          v-if="list.length != 0 && loading === false"
          class="mt-4"
          align="center"
          v-model="gridCurrentPageSeller"
          pills
          :total-rows="gridTotal"
          :per-page="gridItemPerPage"
          @change="gridPageChangeSeller()"
        ></b-pagination>
      </b-col>
    </b-row>
    <popup
      @follow="followUser"
      v-if="content"
      ref="popupItem"
      @next="nextPopup"
      @prev="prevPopup"
      @close="closePopup"
      :item.sync="selectItem"
    />
  </div>
</template>

<script>
import loading from "@/components/global/loading/index";
import buyerMatchList from "../../../../graphql/me/matching/buyer/List.gql";
import sellerMatchList from "@/graphql/me/matching/seller/list.graphql";
import popup from "@/components/app/matching/component/MapPopupComponent";
export default {
  components: {
    loading,
    popup,
  },
  data() {
    return {
      gridCurrentPage: 1,
      gridCurrentPageSeller: 1,
      gridTotal: 0,
      gridItemPerPage: 20,
      popupIndex: 0,
      selectItem: null,
      totalData: null,
      cursors: [],
      test: "deneme",
      list: [],
      content: false,
      loading: true,
      limit: 20,
      cursor: null,
      sellerFilterList: {
        limit: 20,
        page: 1,
        filter: {
          distance: 0,
        },
        orders: [
          { field: "_score", sort: "DESC" },
          { field: "id", sort: "DESC" },
        ],
      },
      cardFilterBuyer: {
        limit: 20,
        page: 1,
        filter: {},
        orders: [
          { field: "_score", sort: "DESC" },
          { field: "id", sort: "DESC" },
        ],
      },
      isLoading: false,
    };
  },
  watch: {
    popupIndex() {
      this.changePopupItem();
    },
    content(val) {
      if (val) {
        this.changePopupItem();
      }
    },
  },
  computed: {
    currentPage() {
        if (this.userRoleType === "BUYER") {
          return this.gridCurrentPage;
        }
        
        if (this.userRoleType === "SELLER") {
            return this.gridCurrentPageSeller;
        }
    },
    totalPage() {
      let totalPage = Math.ceil(this.totalData / this.limit);
      return totalPage;
    },
    data: function () {
      return this.initialState();
    },
    isDisablePrev() {
      if (this.currentPage <= 1) {
        return true;
      }
      if (this.content) {
        this.$nextTick(() => {
          this.$refs.popupItem._data.disabledPrev = false;
        });
      }
      return false;
    },
    isDisableNext() {
      if (this.currentPage >= this.totalPage) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async gridPageChange() {
      await this.$nextTick();
      this.cardFilterBuyer.page = this.gridCurrentPage;
      await this.getList();
      await this.$nextTick();
      this.appScrollTop();
      return true;
    },
    async gridPageChangeSeller() {
      await this.$nextTick();
      this.sellerFilterList.page = this.gridCurrentPageSeller;
      await this.getListBySeller();
      await this.$nextTick();
      this.appScrollTop();
    },
    followUser(val) {
      let id = val.id;
      let status = val.status;
      for (let item of this.list) {
        if (item.member.id === id) {
          item.member.following = status;
        }
      }
    },
    changePopupItem() {
      if (this.content) {
        if (
          this.currentPage >= this.totalPage &&
          this.popupIndex + 1 === this.list.length
        ) {
          this.$nextTick(() => {
            this.$refs.popupItem._data.disabledNext = true;
          });
        } else {
          this.$nextTick(() => {
            this.$refs.popupItem._data.disabledNext = false;
          });
        }

        if (this.currentPage <= 1 && this.popupIndex === 0) {
          this.$nextTick(() => {
            this.$refs.popupItem._data.disabledPrev = true;
          });
        } else {
          this.$nextTick(() => {
            this.$refs.popupItem._data.disabledPrev = false;
          });
        }
      }
    },
    async nextPopup(index) {
      this.$nextTick(() => {
        this.$refs.popupItem._data.isLoading = true;
      });
      let length = this.list.length;
      if (index + 1 === length) {
        await this.nextPage();
        this.$nextTick(() => {
          this.$refs.popupItem._data.itemIndex = 0;
        });

        this.popupIndex = 0;
        this.$nextTick(() => {
           this.$refs.popupItem._data.selectItem = this.selectItem = this.list[0];
        });
        this.changePopupItem();
        return this.$nextTick(() => {
          this.$refs.popupItem._data.isLoading = false;
        });
      }

      this.$nextTick(() => {
        this.selectItem = this.list[++index];
      });
      this.$nextTick(() => {
        this.$refs.popupItem._data.itemIndex = index;
        this.$refs.popupItem.selectItem = this.selectItem;
      });
      this.$nextTick(() => {
        this.popupIndex = index;
      });
      this.$nextTick(() => {
        this.changePopupItem();
      });
      this.$nextTick(() => {
        this.$refs.popupItem._data.isLoading = false;
      });
    },
    async prevPopup(index) {
      this.$refs.popupItem._data.isLoading = true;

      if (index === 0 && this.currentPage !== 1) {
        await this.prevPage();
        await this.$nextTick();
        let length = this.list.length;
        this.$refs.popupItem._data.selectItem = this.selectItem = this.list[length - 1];
        this.$refs.popupItem._data.itemIndex = length - 1;
        this.popupIndex = length - 1;
        this.changePopupItem();
        return (this.$refs.popupItem._data.isLoading = false);
      }
      this.selectItem = this.list[index];
      this.$nextTick(() => {
        this.$refs.popupItem.selectItem = this.selectItem;
      });
      this.$refs.popupItem._data.itemIndex = index;
      this.popupIndex = index;
      this.changePopupItem();
      return (this.$refs.popupItem._data.isLoading = false);
    },
    async nextPage() {
      if (this.userRoleType === "BUYER") {
          this.gridCurrentPage++;
          await this.gridPageChange();
      }
      if (this.userRoleType === "SELLER") {
        this.gridCurrentPageSeller++;
        await this.gridPageChangeSeller();
      }
    },
    async prevPage() {
      if (this.userRoleType === "BUYER") {
          this.gridCurrentPage--;
          await this.gridPageChange();
      }
      if (this.userRoleType === "SELLER") {
        this.gridCurrentPageSeller--;
        await this.gridPageChangeSeller();
      }
    },
    initialState() {
      return {
        selectItem: null,
        totalData: null,
        cursors: [],
        currentPage: 1,
        test: "deneme",
        list: [],
        content: false,
        loading: true,
        limit: 20,
        cursor: null,
        sellerFilterList: {
          limit: 20,
          page: 1,
          filter: {},
          orders: [
            { field: "_score", sort: "DESC" },
            { field: "id", sort: "DESC" },
          ],
        },
        cardFilterBuyer: {
          limit: 20,
          page: 1,
          filter: {},
          orders: [
            { field: "_score", sort: "DESC" },
            { field: "id", sort: "DESC" },
          ],
        },
        isLoading: false,
      };
    },
    async resetComponent() {
      Object.assign(this.$data, this.initialState());
      if (this.userRoleType === "BUYER") {
        await this.getList();
        this.loading = false;
      }
      if (this.userRoleType === "SELLER") {
        await this.getListBySeller();
        this.loading = false;
      }
    },
    closePopup() {
      this.content = false;
    },
    openModal(index) {
      this.content = true;
      this.selectItem = this.list[index];
      this.popupIndex = index;
      this.$nextTick(() => {
        this.$refs.popupItem._data.itemIndex = index;
      });

      //this.$bvModal.show("modal-1");
    },
    async getList() {
      try {
        this.loading = true;
        let data = await this.$apollo.query(buyerMatchList, {
          limit: this.cardFilterBuyer.limit,
          filters: this.cardFilterBuyer.filter,
          orders: this.cardFilterBuyer.orders,
          page: this.cardFilterBuyer.page,
        });
        let response = data("**.list");
        this.gridTotal = data("**.total_item");
        console.log(response);
        //this.cursor = response.next_cursor;
        this.totalData = response.total_item || 0;
        /*if (!this.cursors.includes(response.next_cursor)) {
          this.cursors.push(response.next_cursor);
        } */
        this.list = response.list;
        this.$emit("totalCount", response.total_member);
      } catch (e) {
        console.log(e);
      } finally {
          this.loading = false;
      }
    },
    async getListBySeller(par) {
        try {
            this.loading = true;
            let data = await this.$apollo.query(sellerMatchList, {
                limit: this.sellerFilterList.limit,
                filters: this.sellerFilterList.filter,
                orders: this.sellerFilterList.orders,
                page: this.sellerFilterList.page,
            });
            let response = data("**.list");
            this.gridTotal = data("**.total_item");
            //this.cursor = response.next_cursor;
            this.totalData = response.total_item || 0;
            /*if (!this.cursors.includes(response.next_cursor)) {
                this.cursors.push(response.next_cursor);
            } */

            this.list = response.list;
            this.$emit("totalCount", response.total_member);
        } catch (error) {
            console.log(error);
        } finally {
            this.loading = false;
        }
    },
    testMethod() {
      console.log(this.test);
    },
  },
  async created() {
    this.loading = true;
    let userInfo = this.GET_USER_INFO;
    this.userType = userInfo.type;
    this.loading = false;
  },
};
</script>
