<template>
  <div style="width: 379px;">
    <div v-if="userRoleType === 'BUYER'" class="map-view-popup">
      <div class="map-view-popup-header d-flex align-items-center">
        <div class="member-img">
          <img :src="data.avatar + '?size=90x90'" width="90" height="90" alt="" />
        </div>
        <div class="member-info">
          <h3>
            <a @click.prevent="getUserProfile(data.uid)" href="#">
                  <span class="text-capitalize"> {{ data.name }} {{ data.surname }}</span>
            </a>
          </h3>
          <p>{{ data.title.name }}</p>
          <span class="company_name text-uppercase">{{ data.company.name }}</span>
        </div>
      </div>
      <div class="map-view-popup-item-info">
        <div class="map-view-popup-item-info-header">
          <div class="item-img">
            <img :src="CDN_IMAGE_PREFIX + item.images[0] + '?size=autox50'" height="50" alt="" />
          </div>
          <h3 class="item-name text-capitalize">
            {{ item.title }}
          </h3>
        </div>
        <div class="map-view-popup-item-info-content">
          <div class="item">
            <span> {{$t("myquotes.id")}} </span>
            <p>{{ item.id }}</p>
          </div>
          <div class="item">
            <span> {{$t("myquotes.ppu")}}  </span>
            <p>
              {{ item.currency.symbol }}{{ item.min_price }} -
              {{ item.currency.symbol }}{{ item.max_price }}
            </p>
          </div>
          <div class="item">
            <span> {{$t("myquotes.distance")}}  </span>
            <p>{{ data.distance }}</p>
          </div>
        </div>
      </div>
         <div class="dd-menu">
        <b-dropdown
          right
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content class="p-0">
          <img src="/img/open-card-footer-menu.svg" alt=""> 
            <!-- <v-icon :icon="['fas', 'ellipsis-v']"></v-icon> -->
          </template>
          <b-dropdown-item @click.prevent="getUserProfile(data.uid)" >{{$t("buttons.profile")}} </b-dropdown-item>
          <b-dropdown-item @click="startConversation(data.uid)"  >{{$t("buttons.message")}} </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div v-if="userRoleType === 'SELLER'" class="map-view-popup">
      <div class="map-view-popup-header d-flex align-items-center">
        <div class="member-img">
          <img :src="data.avatar + '?size=90x90'" width="90" height="90" alt="" />
        </div>
        <div class="member-info text -capitalize">
          <h3>
            <a @click.prevent="getUserProfile(data.uid)" href="#">
              {{ data.name }}
              <span class="">{{ data.surname }}</span>
            </a>
          </h3>
          <p>{{ data.title.name }}</p>
          <span class="company_name">{{ data.company.name }}</span>
        </div>
      </div>
      <div class="map-view-popup-item-info">
        <div class="map-view-popup-item-info-header">
          <div class="item-buyer-industry-icon">
            <industry-icon :color="'primary'" :name="item.industry.icon" />
          </div>
          <h3 class="item-name text-capitalize">
            {{ item.title }}
          </h3>
        </div>
        <div class="map-view-popup-item-info-content">
          <div class="item">
            <span> {{$t("myrequests.demand")}}  </span>
            <p>{{ numberFixer(item.demand) }}</p>
          </div>
          <div class="item">
            <span> {{$t("myrequests.label-3")}}  </span>
            <p>{{ item.unit.shortname }}</p>
          </div>
          <div class="item">
            <span> {{$t("myquotes.distance")}}  </span>
            <p>{{ data.distance }}</p>
          </div>
        </div>
      </div>
      <div class="dd-menu">
        <b-dropdown
          right
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content class="p-0">
          <img src="/img/open-card-footer-menu.svg" alt=""> 
            <!-- <v-icon :icon="['fas', 'ellipsis-v']"></v-icon> -->
          </template>
          <b-dropdown-item @click.prevent="getUserProfile(data.uid)" >{{$t("buttons.profile")}} </b-dropdown-item>
          <b-dropdown-item @click="startConversation(data.uid)"  >{{$t("buttons.message")}} </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "MapboxPopupContent",
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      item: {
        title: null,
        desc: null,
        id: null,
        currency: null,
        min_price: null,
        max_price: null,
        images: [],
      },
    };
  },
  watch: {
      member() {
          this.build();
      }
  },
  methods: {
    parse(data) {
        return _.isString(data) ? JSON.parse(data) : data;
    },
    build() {
        this.item.industry = this.parse(this.member.properties.industry);
        if (this.userRoleType === "BUYER") {
            this.data = this.parse(this.member.properties.member);
            let contents = this.parse(this.member.properties.contents);
            this.item.title = this.getDefault(contents).name;
            this.item.desc = this.getDefault(contents).description;
            this.item.id = this.member.properties.product_id;
            this.item.min_price = this.member.properties.min_price;
            this.item.max_price = this.member.properties.max_price;
            this.item.images = this.parse(this.member.properties.images);
            this.item.currency = this.parse(this.member.properties.currency);
        }
        if (this.userRoleType === "SELLER") {
            this.data = this.parse(this.member.properties.member);
            let contents = this.parse(this.member.properties.contents);
            this.item.unit = this.parse(this.member.properties.unit);
            this.item.title = this.getDefault(contents).name;
            this.item.desc = this.getDefault(contents).description;
            this.item.demand = this.member.properties.demand;
        }
    }
  },
  created() {
      this.build();    
  },
};
</script>
