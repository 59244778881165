<template>
  <div class="app-content mt-0 d-flex flex-column">
    <div class="matching-filter pl-0">
      <b-container class="bv-example-row py-3 px-0 filter-fixed-section zoom" fluid>
        <!--jimir3x-->
        <!-- <b-container
        class="bv-example-row py-3 px-0 filter-fixed-section"
        fluid
        style="position: fixed; top: 70px;"
      > -->
        <!--jimir3x-->
        <b-row class="px-4 justify-content-space between" style="margin-top:-5px;">
          <b-col lg="9" md="3" sm="12" xs="12" class="d-flex">
            <div
              class="d-flex justify-content-space-between align-items-center h-100"
              style="margin-bottom:10px"
            >
              <div
                class="matching-filter-left align-items-left-center"
                style="display: flex; flex-wrap: wrap;"
              >
              <div class="match-number-left">
                <h3  v-if="userRoleType === 'BUYER'" class="mr-5 ">
                  {{ $t('mw.general.sellers-matched') }}
                  <span class="matched-number-distance ml-3"
                    > {{ totalMember }}
                  </span>
                </h3>
                
                <h3 v-if="userRoleType === 'SELLER'" class="mr-5">
                  {{ $t('mw.general.buyers-matched') }}
                  <span class="matched-number-distance ml-3"
                    >{{ totalMember }}
                  </span>
                </h3>

                <h3>
                  {{ $t('mw.general.distance') }}
                  <span class="matched-number-distance ml-3">
                    {{distanceRange }}
                    {{ GET_USER_INFO.distance_unit }}
                  </span>
                </h3>
                </div>
                <div class="matching-filter-right mr-3">
                
                <button
                  style="border-radius:100px !important;"
                  @click="openSelectDistanceModal"
                  class="btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center btn-location-change  "
                >
                 <div class="d-flex">
                    <span v-if="!changeLocationData.place_name" class="ml-2 ls-05" style="">{{
                      `${activeLocation.address.city || 'unnamed'} / ${activeLocation.address.country} `
                    }}</span>
                    <span
                      v-if="changeLocationData.place_name"
                      class="ml-2 "
                      style=""
                    >
                      {{ changeLocationData.place_name }}
                    </span>
                  </div> 
                  <div class="location-icon">
                    <svg style="width:24px;height:24px " viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12 4C14.2 4 16 5.8 16 8C16 10.1 13.9 13.5 12 15.9C10.1 13.4 8 10.1 8 8C8 5.8 9.8 4 12 4M12 2C8.7 2 6 4.7 6 8C6 12.5 12 19 12 19S18 12.4 18 8C18 4.7 15.3 2 12 2M12 6C10.9 6 10 6.9 10 8S10.9 10 12 10 14 9.1 14 8 13.1 6 12 6M20 19C20 21.2 16.4 23 12 23S4 21.2 4 19C4 17.7 5.2 16.6 7.1 15.8L7.7 16.7C6.7 17.2 6 17.8 6 18.5C6 19.9 8.7 21 12 21S18 19.9 18 18.5C18 17.8 17.3 17.2 16.2 16.7L16.8 15.8C18.8 16.6 20 17.7 20 19Z" />
                    </svg>
                    <!-- <v-icon :icon="['fac', 'location']"></v-icon> -->
                  </div>
                 
                </button>

              </div>

                <!-- FILTER BUTTON -->

               <div>

                 <div class="matching-filter-right mr-3">
                 <button 
                 style="border-radius:100px !important; margin-left:40px;"
              class="btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center btn-location-change "
              @click="() => $refs.filtersModal.open()">
              
              
                
                  <p class="ls-05" style="margin:0px">
                
                {{$t("General.filters")}} </p> 

                <div class="location-icon">
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M15 17H18V14H20V17H23V19H20V22H18V19H15V17M13 19.88C13.04 20.18 12.94 20.5 12.72 20.71C12.32 21.1 11.69 21.1 11.3 20.71L7.29 16.7C7.06 16.47 6.96 16.16 7 15.87V10.75L2.21 4.62C1.87 4.19 1.95 3.56 2.38 3.22C2.57 3.08 2.78 3 3 3V3H17V3C17.22 3 17.43 3.08 17.62 3.22C18.05 3.56 18.13 4.19 17.79 4.62L13 10.75V19.88M5.04 5L9 10.07V15.58L11 17.58V10.05L14.96 5H5.04Z" />
                    </svg>
                    <!-- <v-icon :icon="['fac', 'location']"></v-icon> -->
                  </div>
                
                
                </button>

                 
                </div>
                  </div>

        <div class="searchbar-div">
            <input spellcheck="true" class="dashboard-searchbar  d-flex justfiy-content-between align-items-center "
                :placeholder="$t('General.sb-1')" 
                v-model="search"
                @keyup.enter="doSearch()"
               
                type="search" 
                name="" 
                id=""  
            > 
            <a 
                v-if="searched === false && search != ''"
                href="#"
                class="search-icon-dash"
                @click.prevent="doSearch(false)"
            >
                {{ $t("messenger.search")}}
            </a>
            <a 
                v-else
                href="#"
                class="search-icon-dash"
                @click.prevent="doSearch(true)"
            >
                Reset
            </a>
        </div>

              </div>
            </div>
          </b-col>

          <!-- <b-col lg="0" md="0" sm="0" xs="0">

          </b-col> -->
          <b-col lg="3" md="3" sm="12" xs="12"  >
            <div
              class="matching-filter-right view-icons d-flex justify-content-space-between align-items-center h-100"
              style="justify-content: flex-end; margin-bottom:10px"
            >
              
              <!-- <div class="matching-filter-right-item">
                  <button
                    @click="openFilters = !openFilters"
                    class="btn btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center"
                  >
                    <div>Filters2</div>
                    <div class="ml-5">
                      <v-icon :icon="'sliders-h'" rotation="90" />
                    </div>
                  </button>
                </div> -->
              <div class="matching-filter-right-item view-icons ">
                <a
                  @click.prevent="changeView('map')"
                  href="#"
                  class="icon-btn"
                  :class="{ active: view === 'map' }"
                >
                  <div class="matching-filter-right-item__tooltip">
                    <span>{{$t("General.map-view")}}</span>
                    <div />
                  </div>
                  <!--<v-icon :icon="['fas', 'map']"></v-icon> -->
                  <svg  style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M20.5,3L20.34,3.03L15,5.1L9,3L3.36,4.9C3.15,4.97 3,5.15 3,5.38V20.5A0.5,0.5 0 0,0 3.5,21L3.66,20.97L9,18.9L15,21L20.64,19.1C20.85,19.03 21,18.85 21,18.62V3.5A0.5,0.5 0 0,0 20.5,3M10,5.47L14,6.87V18.53L10,17.13V5.47M5,6.46L8,5.45V17.15L5,18.31V6.46M19,17.54L16,18.55V6.86L19,5.7V17.54Z"
                    />
                  </svg>
                </a>
                
              </div>
              <div class="matching-filter-right-item view-icons">
                <a
                  @click.prevent="changeView('card')"
                  href="#"
                  class="icon-btn"
                  :class="{ active: view === 'card' }"
                >
                  <div class="matching-filter-right-item__tooltip">
                    <span>{{$t("General.grid-view")}}</span>
                    <div />
                  </div>
                  <svg style="width:22px;height:22px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M3,11H11V3H3M3,21H11V13H3M13,21H21V13H13M13,3V11H21V3"
                    />
                  </svg>
                </a>
              </div>
              <div class="matching-filter-right-item view-icons">
                <a
                  @click.prevent="changeView('list')"
                  href="#"
                  class="icon-btn"
                  :class="{ active: view === 'list' }"
                >
                  <div class="matching-filter-right-item__tooltip">
                    <span>{{$t("General.list-view")}}</span>
                    <div/>
                  </div>
                  <svg style="width:26px;height:26px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z"
                    />
                  </svg>
                  <!-- <v-icon icon="list"></v-icon> -->
                </a>
              </div>


               <div class="role-button-responsive-1">
              <!-- <div class="role-change-seperator-div"></div>             -->
                <b-button
                    v-if="userRole === 'BUYER'"
                    @click="changeRoleType('SELLER')"
                    style="border-radius:100px !important; line-height:16px"
                    class="btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center btn-role-change "
                >
                    {{$t("General.show-sellers")}}

                    <img
                        class="header__navigation-img"
                        style="margin-left:20px"
                        src="/img/switch-account.svg"
                        alt=""
                    />
                </b-button>
                <b-button
                    v-else
                    @click="changeRoleType('BUYER')"
                    style="border-radius:100px !important"
                    class="btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center btn-role-change "
                >
                    {{$t("General.show-buyers")}}

                    <img
                        class="header__navigation-img"
                        style="margin-left:20px"
                        src="/img/switch-account.svg"
                        alt=""
                    />
                </b-button>
                </div>
                 <div class="role-button-responsive-2">
              <!-- <div class="role-change-seperator-div"></div>             -->
                <b-button
                    v-if="userRole === 'BUYER'"
                    @click="changeRoleType('SELLER')"
                    style="border-radius:100px !important"
                    class="btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center btn-role-change "
                >
                    {{$t("General.show-sellers-res")}}

                    <img
                        class="header__navigation-img"
                        style="margin-left:20px"
                        src="/img/switch-account.svg"
                        alt=""
                    />
                </b-button>
                <b-button
                    v-else
                    @click="changeRoleType('BUYER')"
                    style="border-radius:100px !important"
                    class="btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center btn-role-change "
                >
                   {{$t("General.show-buyers-res")}}

                    <img
                        class="header__navigation-img"
                        style="margin-left:20px"
                        src="/img/switch-account.svg"
                        alt=""
                    />
                </b-button>
                </div>
            </div>
          </b-col>
        </b-row>

        <b-row
          class="selected-filter-items px-5"
          v-show="
            (Array.isArray(filters.items) &&
              filters.items.length) ||
            (Array.isArray(filters.selectUserIndustry) &&
              filters.selectUserIndustry.length) ||
              (Array.isArray(filters.productIndustry) &&
                filters.productIndustry.length) ||
              (Array.isArray(filters.selectTitles) &&
                filters.selectTitles.length) ||
              (Array.isArray(filters.selectDeparments) &&
                filters.selectDeparments.length)
          "
        >

          <a
            v-for="(item, index) in filters.items"
            :key="'itemxx-' + index"
            class="mr-3 selected-industry-item  selected-green"
          >
            #{{ 
                userRoleType === 'SELLER' 
                    ? getDefault($_.find(mproducts, { id: item }).contents).name 
                    : getDefault($_.find(minquiries, { id: item }).contents).name 
            }}
            <img
              @click="() => filters.items.splice(index, 1)"
              src="/img/cross.svg"
              alt=""
            />
          </a>

          <a
            v-for="(selectedIndustryItem, index) in filters.selectUserIndustry"
            :key="'ii-' + index"
            class="mr-3 selected-industry-item"
          >
            #{{
              fetchFilterData.userIndustries.find(
                x => x.id == selectedIndustryItem
              ).label
            }}
            <img
              @click="removeFromSelectedIndustry(index)"
              src="/img/cross.svg"
              alt=""
            />
          </a>

          <a
            v-for="(selectedProductIndustryItem,
            index) in filters.productIndustry"
            :key="'pi-' + index"
            class="mr-3 selected-industry-item"
          >
            #{{
              fetchFilterData.productIndustires.find(
                x => x.id == selectedProductIndustryItem
              ).label
            }}
            <img
              @click="removeFromProductIndustry(index)"
              src="/img/cross.svg"
              alt=""
            />
          </a>

          <a
            v-for="(selectedTitlesItem, index) in filters.selectTitles"
            :key="'ti-' + index"
            class="mr-3 selected-industry-item"
          >
            #{{
              fetchFilterData.titles.find(x => x.id == selectedTitlesItem).label
            }}
            <img
              @click="removeFromselectTitles(index)"
              src="/img/cross.svg"
              alt=""
            />
          </a>

          <a
            v-for="(selectedDeparmentsItem, index) in filters.selectDeparments"
            :key="'di-' + index"
            class="mr-3 selected-industry-item"
          >
            #{{
              fetchFilterData.tmp.find(x => x.id == selectedDeparmentsItem)
                .label
            }}
            <img
              @click="removeFromDepermants(index)"
              src="/img/cross.svg"
              alt=""
            />
          </a>
        </b-row>
        
      </b-container>

      <!-- <div class="d-flex justify-content-between align-items-center h-100">
        <div class="matching-filter-left d-flex">
          <h3 v-if="userRoleType === 'BUYER'" class="mr-5">
            Sellers Matched   <span class="matched-number-distance ml-3">{{ totalMember }} </span>
          </h3>
          <h3 v-if="userRoleType === 'SELLER'" class="mr-5">
            Buyers Matched <span class="matched-number-distance ml-3">{{ totalMember }} </span>
          </h3>

          <h3>Match Distance   <span class="matched-number-distance ml-3">200 KM</span></h3>


        </div>

      
          <b-row cols="12" class="w-100">
                  <b-col>
                    <div class="more-filters-item">
                      <multi-select
                        track-by="name"
                        label="name"
                        :multiple="true"
                        placeholder="User Industry"
                        :clearOnSelect="false"
                        :closeOnSelect="false"
                        :allowEmpty="true"
                        :showLabels="true"
                        v-model="filters.selectUserIndustry"
                        :options="fetchFilterData.userIndustries"
                      >
                        <template slot="selection" slot-scope="{ values, search, isOpen }"
                          ><span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                            >{{ values.length }} user industry selected</span
                          ></template
                        >

                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title"
                              >{{ props.option.name }}
                              <small>({{ props.option.count }})</small></span
                            >
                          </div>
                        </template>
                      </multi-select>
                </div>
                  </b-col>
                  <b-col>
                    <div class="more-filters-item">
                  <multi-select
                    track-by="name"
                    label="name"
                    :multiple="true"
                    :clearOnSelect="false"
                    :hideSelected="false"
                    :allowEmpty="true"
                    :showLabels="true"
                    :closeOnSelect="false"
                    :placeholder="
                      userRoleType === 'SELLER'
                        ? 'Inqury industry'
                        : 'Product industry'
                    "
                    :searchable="true"
                    v-model="filters.productIndustry"
                    :options="fetchFilterData.productIndustires"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }}
                        {{ userRoleType === "SELLER" ? "inqury" : "product" }}
                        industry selected</span
                      ></template
                    >

                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ props.option.name }}
                          <small>({{ props.option.count }})</small></span
                        >
                      </div>
                    </template>
                  </multi-select>
                </div>
                  </b-col>
                  <b-col>
                    <div class="more-filters-item">
                  <multi-select
                    track-by="name"
                    @close="closeTitleSelectBox"
                    label="name"
                    :clearOnSelect="false"
                    :multiple="true"
                    :hideSelected="false"
                    :closeOnSelect="false"
                    :allowEmpty="true"
                    :searchable="true"
                    :placeholder="'Company Title'"
                    v-model="filters.selectTitles"
                    :options="fetchFilterData.titles"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} title selected</span
                      ></template
                    >

                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ props.option.name }}
                          <small>({{ props.option.count }})</small></span
                        >
                      </div>
                    </template>
                  </multi-select>
                </div>
                  </b-col>
                  <b-col>
                    <div class="more-filters-item">
                  <multi-select
                    :disabled="!selectedTitles"
                    track-by="id"
                    label="name"
                    :placeholder="'User Deparments'"
                    :multiple="true"
                    :clearOnSelect="false"
                    :closeOnSelect="false"
                    :hideSelected="false"
                    :searchable="true"
                    v-model="filters.selectDeparments"
                    :options="fetchFilterData.positions"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} depertmant selected</span
                      ></template
                    >

                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.title_name }} > {{ props.option.name }}
                          <small>({{ props.option.count }})</small></span
                        >
                      </div>
                    </template>
                  </multi-select>
                </div>
                  </b-col>
                </b-row>
                
        

      <div class="d-flex distance-slider align-items-center mr-3">
            <span class="icon">
              <v-icon icon="home"></v-icon>
            </span>
              <vue-slider 
              
              style="width:200px"
              v-model="distanceRange"
              :max="2500"
              
              /> 

         <vue-slider
              :tooltip="'always'"
              v-if="openModal"
              :max="2500"
              ref="slider"
              class="w-100"
              :class="{ 'has-error': errorDistance }"
              :tooltip-formatter="formatter"
              v-model="distanceRange"
            ></vue-slider> 
            <span class="icon">
              <v-icon :icon="['fac', 'road']"></v-icon>
            </span>
          </div>
         
        <div class="matching-filter-right d-flex align-items-center ">
         
          <div class="matching-filter-right-item mr-3">
            <button
              @click="openSelectDistanceModal"
              class="btn btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center btn-location-change"
            >
              <div class="d-flex">
                <span v-if="!changeLocationData.place_name" class="ml-2">{{
                  `${GET_USER_INFO.address.city} / ${GET_USER_INFO.address.country} `
                }}</span>
                <span
                  v-if="changeLocationData.place_name"
                  class="ml-2 d-block"
                  style="width: 106px; overflow: hidden;
                  white-space: nowrap;
                   text-overflow: ellipsis; "
                >
                  {{ changeLocationData.place_name }}
                </span>
              </div>
              <div class="ml-5">
                <v-icon :icon="['fac', 'location']"></v-icon>
              </div>
            </button>
          </div>
         <div class="matching-filter-right-item">
            <button
              @click="openFilters = !openFilters"
              class="btn btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center"
            >
              <div>Filters2</div>
              <div class="ml-5">
                <v-icon :icon="'sliders-h'" rotation="90" />
              </div>
            </button>
          </div> 
          <div class="matching-filter-right-item">
            <a
              @click.prevent="changeView('map')"
              href="#"
              class="icon-btn"
              :class="{ active: view === 'map' }"
            >
              <v-icon :icon="['fac', 'location']"></v-icon>
            </a>
          </div>
          <div class="matching-filter-right-item">
            <a
              @click.prevent="changeView('card')"
              href="#"
              class="icon-btn"
              :class="{ active: view === 'card' }"
            >
              <v-icon icon="border-all"></v-icon>
            </a>
          </div>
          <div class="matching-filter-right-item">
            <a
              @click.prevent="changeView('list')"
              href="#"
              class="icon-btn"
              :class="{ active: view === 'list' }"
            >
              <v-icon icon="list"></v-icon>
            </a>
          </div>
        </div>
      </div> -->

      <!-- <div v-if="openFilters" class="row d-flex w-100 justify-content-between pb-3 bg-white filter-section px-3"> -->

      <!--<div class="row d-flex w-100 justify-content-between pb-3 bg-white filter-section px-3">

      <div class="row d-flex w-100 justify-content-between pb-3 bg-white filter-section px-3">


        <div class="col-12 d-flex align-items-center">
         <div class="more-filters-item">
            <span>More Filters:</span>
          </div>
          <div class="row d-flex w-100 justify-content-between pb-3 bg-white">
            <div class="col-8 d-flex align-items-center px-0">

                <b-row cols="12" class="w-100">
                  <b-col>
                    <div class="more-filters-item">
                      <multi-select
                        track-by="name"
                        label="name"
                        :multiple="true"
                        placeholder="User Industry"
                        :clearOnSelect="false"
                        :closeOnSelect="false"
                        :allowEmpty="true"
                        :showLabels="true"
                        v-model="filters.selectUserIndustry"
                        :options="fetchFilterData.userIndustries"
                      >
                        <template slot="selection" slot-scope="{ values, search, isOpen }"
                          ><span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                            >{{ values.length }} user industry selected</span
                          ></template
                        >

                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title"
                              >{{ props.option.name }}
                              <small>({{ props.option.count }})</small></span
                            >
                          </div>
                        </template>
                      </multi-select>
                </div>
                  </b-col>
                  <b-col>
                    <div class="more-filters-item">
                  <multi-select
                    track-by="name"
                    label="name"
                    :multiple="true"
                    :clearOnSelect="false"
                    :hideSelected="false"
                    :allowEmpty="true"
                    :showLabels="true"
                    :closeOnSelect="false"
                    :placeholder="
                      userRoleType === 'SELLER'
                        ? 'Inqury industry'
                        : 'Product industry'
                    "
                    :searchable="true"
                    v-model="filters.productIndustry"
                    :options="fetchFilterData.productIndustires"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }}
                        {{ userRoleType === "SELLER" ? "inqury" : "product" }}
                        industry selected</span
                      ></template
                    >

                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ props.option.name }}
                          <small>({{ props.option.count }})</small></span
                        >
                      </div>
                    </template>
                  </multi-select>
                </div>
                  </b-col>
                  <b-col>
                    <div class="more-filters-item">
                  <multi-select
                    track-by="name"
                    @close="closeTitleSelectBox"
                    label="name"
                    :clearOnSelect="false"
                    :multiple="true"
                    :hideSelected="false"
                    :closeOnSelect="false"
                    :allowEmpty="true"
                    :searchable="true"
                    :placeholder="'Company Title'"
                    v-model="filters.selectTitles"
                    :options="fetchFilterData.titles"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} title selected</span
                      ></template
                    >

                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ props.option.name }}
                          <small>({{ props.option.count }})</small></span
                        >
                      </div>
                    </template>
                  </multi-select>
                </div>
                  </b-col>
                  <b-col>
                    <div class="more-filters-item">
                  <multi-select
                    :disabled="!selectedTitles"
                    track-by="id"
                    label="name"
                    :placeholder="'User Deparments'"
                    :multiple="true"
                    :clearOnSelect="false"
                    :closeOnSelect="false"
                    :hideSelected="false"
                    :searchable="true"
                    v-model="filters.selectDeparments"
                    :options="fetchFilterData.positions"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} depertmant selected</span
                      ></template
                    >

                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.title_name }} > {{ props.option.name }}
                          <small>({{ props.option.count }})</small></span
                        >
                      </div>
                    </template>
                  </multi-select>
                </div>
                  </b-col>
                </b-row>
                
                
                
                
                
            </div>
            <div class="col-4">
              <div class="more-filters-item text-center">
                  <a @click.prevent="applyFilter" href="#" class="d-block">Search</a> 
                  <a @click.prevent="resetFilter" href="#" class="d-block mt-2"
                    >Clear Filters</a
                  >
                </div>
            </div>
          </div>

          
        </div>
      </div>
    </div> -->
      <div v-if="view === 'map'" class="container-fluid pr-0 dashboard-content calc" tabindex="0">
        <div class="row flex-grow-1 calc10">
          <div v-if="loading" class="col-xl-3 col-lg-3 mt-0">
            <loading :loading="true" />
          </div>
          <div v-else-if="mapItemList.length === 0" class="col-xl-3 col-lg-3 mt-0">
            <div
              style="
                height:97.5%;
                margin-top: 15px;
              "
              class="card no-match-map"
              v-if="userRoleType === 'BUYER'"
            >
              <div class="card-body py-5 text-center zoom calc">
                <div
                  style="
                    background: #f8f8f8;
                    opacity: 1;
                    border-radius: 1px;
                    padding: 15px 27px 0 20px;
                    text-align: left;
                    height: 80vh;
                    border-radisu:5px;
                  "
                >
                  <h4>{{ $t('mw.nomatch-buyer.title') }}</h4>
                  <hr />
                  <p>
                    {{ $t('mw.nomatch-buyer.p1') }}
                  </p>
                  <h5>{{ $t('mw.nomatch-buyer.q?') }}</h5>
                  <ul>
                    <li>
                      {{ $t('mw.nomatch-buyer.p2') }}
                    </li>
                    <li>
                      {{ $t('mw.nomatch-buyer.p3') }}
                    </li>
                    <li>
                      {{ $t('mw.nomatch-buyer.p4') }}
                    </li>
                  </ul>

                  <p class="mb-5">
                    {{ $t('mw.nomatch-buyer.p5') }}
                  </p>

                  <button
                    @click="openAddInquiryModal()"
                    v-if="userRoleType === 'BUYER'"
                    class="btn btn-primary btn-sm"
                    style="padding: 5px 20px !important"
                  >
                    {{ $t('buttons.cr') }}
                  </button>
                </div>
              </div>
            </div>
            <div
            style="
             height:97.5%;
             margin-top: 15px;"
              class="card no-match-map "
             
              v-if="userRoleType === 'SELLER'"
            >
              <div class="card-body py-5 text-center zoom calc">
                <div class=""
                  style="
                    background: #f8f8f8;
                    opacity: 1;
                    border-radius: 1px;
                    padding: 15px 27px 0 20px;
                    text-align: left;
                    height: 80vh;
                    border-radisu:5px;
                  "
                >
                  <h4>{{ $t('mw.nomatch-seller.title') }}</h4>
                  <hr />
                  <p>
                    {{ $t('mw.nomatch-seller.p1') }}
                  </p>
                  <h5>{{ $t('mw.nomatch-seller.q?') }}</h5>
                  <ul>
                    <li>
                      {{ $t('mw.nomatch-seller.p2') }}
                    </li>
                    <li>
                      {{ $t('mw.nomatch-seller.p3') }}
                    </li>
                    <li>
                      {{ $t('mw.nomatch-seller.p4') }}
                    </li>
                  </ul>

                  <p class="mb-5">
                    {{ $t('mw.nomatch-seller.p5') }}
                  </p>

                  <button
                     @click="openAddProductModal()"
                
                    class="btn btn-primary btn-sm"
                    style="padding: 5px 20px !important"
                  >
                    {{ $t('buttons.cq') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="col-xl-3 col-lg-3 pr-0 pl-1 pb-0"
          >
            <div class="form-group sort-card-list" style="margin-bottom: 4px">
              <!--<multi-select
                @select="changeSort"
                :disabled="mapItemList.length === 0"
                class="filterMultiSelect"
                track-by="name"
                label="name"
                :clearOnSelect="false"
                :hideSelected="false"
                :allowEmpty="false"
                :showLabels="false"
                :searchable="false"
                v-model="sort.value"
                :options="sortFilterData"
              >
                <template slot="singleLabel" slot-scope="props"
                  ><div class="d-flex align-items-center">
                    <span class="option__desc infoSpan mr-3 d-block"
                      >Sort by:</span
                    ><span class="option__title d-block">{{
                      props.option.name
                    }}</span>
                  </div></template
                >

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </div>
                </template>
              </multi-select> -->
            </div>
            <div v-if="selectedMapListItem == null" class="card-list-wrapper calc">
              <transition-group name="flip-list">
                <div
                  v-for="item in mapItemList"
                  :key="item.id"
                  class="card-list-item"
                  :id="'mpl-item' + item.id"
                >
                  <div
                    @click="selectMapListItem(item)"
                    v-if="userRoleType === 'SELLER'"
                    class="card buyer-map-card zoom"
                    :style="
                      item.top === 1 ? 'box-shadow: 0 0 0px 1px #198cff;' : ''
                    "
                  >
                    <div class="row map-list-match-rate-ratio mx-0">
                      <div class="col-md-6 text-left mx-0 px-0">
                        <h6>{{ $t('mw.general.matchrate') }}</h6>
                      </div>
                      <div class="col-md-6 text-right mx-0 px-0">
                        <h5>% {{ item._score.toFixed(1) }}</h5>
                      </div>
                    </div>
                    <div class="buyer-map-card-head d-flex w-100">
                      <div class="item-head-info-shell w-100">
                        <h3 class="text-capitalize">
                          {{ findSubItemByLang(item.contents).name }}
                        </h3>
                        <p class="buyer-map-card-p">
                          {{ findSubItemByLang(item.contents).description }}
                        </p>
                        <div class="row" style="margin-bottom:0px;">
                          <div class="col-md-2">
                            <p class="buyer-map-card-unit-shell">
                              {{ $t('mw.general.unit') }}
                            </p>
                          </div>
                          <div class="col-md-4">
                            <p
                              class="buyer-map-card-unit-content-shell text-capitalize"
                            >
                              {{ item.unit.name }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="buyer-map-card-unit-shell">
                              {{ $t('mw.general.demand') }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p
                              class="buyer-map-card-unit-content-shell text-capitalize"
                            >
                              {{ numberFixer(item.demand) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-list-item-footer d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex justify-content-space-between align-items-center">
                        <img
                          :src="`${item.member.avatar}?size=24x24`"
                          alt=""
                          width="24"
                          height="24"
                          class="rounded-circle mr-4 ml-0 pl-0"
                        />
                        <div class="aaaaa" style="text-transform:capitalize">
                          
                            {{ shorter(item.member.name, item.member.surname) }}
                        
                          <img
                            v-if="$_.get(item, 'member.verification.icon')"
                            :src="$_.get(item, 'member.verification.icon')"
                            class="img-fluid"
                            alt=""
                            style="
                                width: 14px;
                                height: 14px;
                                align-items: center;
                                margin-left: 5px;
                                margin-top: -2px;
                            "
                          />
                          
                        </div>

                        <div class="company_small_screen"
                        >
                      
                        <span class="ml-4 mr-4">|</span>
                        <div 
                           class="word-break-two"
                          style="text-transform:uppercase; ">{{
                            item.member.company.name
                          }}</div>
                          </div>
                      </div>

                      <div
                        style="top: 0; right: -15px"
                        class="dd-menu position-relative px-2"
                      >
                        <!-- <b-dropdown
                            right
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                        >
                            <template #button-content class="p-0">
                            <v-icon :icon="['fas', 'ellipsis-v']"></v-icon>
                            <img
                                src="/img/open-card-footer-menu.svg"
                                class="img-fluid"
                                alt=""
                                style="width: 16px; height: 16px"
                            />
                            </template>
                            <b-dropdown-item href="#">Profile</b-dropdown-item>
                            <b-dropdown-item href="#">Message</b-dropdown-item>
                        </b-dropdown> -->
                        <img
                          v-if="$_.get(item, 'member.group.icon')"
                          :src="$_.get(item, 'member.group.icon')"
                          class="img-fluid map-view-card-icon-star"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="userRoleType === 'BUYER'"
                    class="card seller-map-card2 zoom"
                    :style="
                      item.top === 1 ? 'box-shadow: 0 0 0px 1px #198cff;' : ''
                    "
                  >
                    <!--<div class="map-list-industry-icon">
                        <industry-icon
                        :color="'primary'"
                        :name="item.industry.icon"
                        />
                    </div> -->

                    <div @click="selectMapListItem(item)" class="card-body">
                      <div class="row map-list-match-rate-ratio mx-0">
                        <div class="col-md-6 text-left mx-0 px-0">
                          <h6>{{ $t('mw.general.matchrate') }}</h6>
                        </div>
                        <div class="col-md-6 text-right mx-0 px-0">
                          <h5>%{{ item._score.toFixed(1) }}</h5>
                        </div>
                      </div>
                      <div class="row seller-map-view-left-card">
                        <div class="col-md-12">
                          <h3 class="text-capitalize">
                            {{ findSubItemByLang(item.contents).name }}
                          </h3>
                        </div>
                      </div>
                      <div class="card-list-item-head d-flex">
                        <div class="map-list-view-avatar-div">
                          <img
                            :src="
                              CDN_IMAGE_PREFIX +
                                `${item.images[0]}?size=autox120`
                            "
                            alt=""
                            class="map-list-view-avatar"
                          />
                        </div>
                        <div class="w-100 seller-map-view-avatar-section">
                          <!--  <h3 class="text-capitalize">
                            {{ getDefault(item.contents).name }}
                            </h3>
                            <span class="id">ID {{ item.product_id }}</span>
                            <p class="desc">
                            {{ getDefault(item.contents).description }}
                            </p> -->
                          <div class="card-list-item-content">
                            <div class="card-list-item-content-item d-flex">
                              <p class="w-25 key">
                                {{ $t('mw.general.price') }}
                              </p>
                              <p class="w-75 value">
                                {{ item.currency.symbol }}{{ item.min_price }} -
                                {{ item.currency.symbol }}{{ item.max_price }}
                                <span class="ml-2 text-lowercase">/ {{ item.unit.shortname }}
                                </span>
                              </p>
                            </div>
                            <div class="card-list-item-content-item d-flex">
                              <p class="w-25 key">{{ $t('mw.general.moq') }}</p>
                              <p class="w-75 value text-capitalize">
                                {{ numberFixer(item.moq) }}
                                <span class="ml-2 text-lowercase">
                                  {{ item.unit.shortname }}</span
                                >
                              </p>
                            </div>

                            <div class="card-list-item-content-item d-flex">
                              <p class="w-25 key">
                                {{ $t('mw.general.supply') }}
                              </p>
                              <p class="w-75 value text-lowercase">
                                {{ numberFixer(item.stock) }}
                                <span>
                                  {{ item.unit.shortname }} /
                                  {{ $t('mw.general.month') }}</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--<div class="card-list-item-description">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Amet justo donec enim diam. Id semper
                            risus in hendrerit gravida rutrum.
                        </p>
                        </div> -->
                      <!-- <div class="card-list-item-content">
                        <div class="card-list-item-content-item d-flex">
                            <p class="w-25 key">Price</p>
                            <p class="w-75 value">
                            {{ item.currency.symbol }}{{ item.min_price }} -
                            {{ item.currency.symbol }}{{ item.max_price }}
                            <span class="ml-2 text-lowercase">
                                {{ item.unit.name }}</span
                            >
                            </p>
                        </div>
                        <div class="card-list-item-content-item d-flex">
                            <p class="w-25 key">M.O.Q</p>
                            <p class="w-75 value text-capitalize">
                            {{ item.moq }}
                            <span class="ml-2 text-lowercase">
                                {{ item.unit.name }}</span
                            >
                            </p>
                        </div>

                        <div class="card-list-item-content-item d-flex">
                            <p class="w-25 key">Supply</p>
                            <p class="w-75 value text-lowercase">
                            {{ item.stock }} <span>/ month</span>
                            </p>
                        </div>
                        </div> -->

                          <!-- Seller CARD -->

                      <div
                        class="card-list-item-footer d-flex align-items-center justify-content-between"
                      >
                        <div class="d-flex justify-content-space-between align-items-center">
                          <img
                            :src="`${item.member.avatar}?size=24x24`"
                            alt=""
                            width="24"
                            height="24"
                            class="rounded-circle mr-4 ml-0 pl-0"
                          />
                          <span class="" style="text-transform:capitalize;  ">
                            {{ shorter(item.member.name, item.member.surname) }}
                            <img
                              v-if="$_.get(item, 'member.verification.icon')"
                              :src="$_.get(item, 'member.verification.icon')"
                              class="img-fluid"
                              alt=""
                              style="
                                width: 14px;
                                height: 14px;
                                align-items: center;
                                margin-left: 5px;
                                margin-top: -2px;
                                "
                            />
                            
                          </span>
                          <div class="company_small_screen">
                          <span class="ml-4 mr-4">|</span>
                          <div 
                           class="word-break-two"
                          style="text-transform:uppercase;">{{
                            item.member.company.name
                          }}</div>
                          </div>
                        </div>

                        <!-- <div
                            style="top: 0; right: 0"
                            class="dd-menu position-relative px-2"
                        >
                            <b-dropdown
                            right
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            >
                            <template #button-content class="p-0">
                                <v-icon :icon="['fas', 'ellipsis-v']"></v-icon>
                            </template>
                            <b-dropdown-item href="#">Profile</b-dropdown-item>
                            <b-dropdown-item href="#">Message</b-dropdown-item>
                            </b-dropdown>
                            <img
                            src="/img/seller-star-icon.svg"
                            class="img-fluid map-view-card-icon-star"
                            alt=""
                            />
                        </div> -->

                        <div
                          style="top: 0; right: -15px"
                          class="dd-menu position-relative px-2"
                        >
                          <!-- <b-dropdown
                            right
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            >
                            <template #button-content class="p-0">
                                <v-icon :icon="['fas', 'ellipsis-v']"></v-icon>
                                <img
                                src="/img/open-card-footer-menu.svg"
                                class="img-fluid"
                                alt=""
                                style="width: 16px; height: 16px"
                                />
                            </template>
                            <b-dropdown-item href="#">Profile</b-dropdown-item>
                            <b-dropdown-item href="#">Message</b-dropdown-item>
                            </b-dropdown> -->
                          <img
                            v-if="$_.get(item, 'member.group.icon')"
                            :src="$_.get(item, 'member.group.icon')"
                            class="img-fluid map-view-card-icon-star"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition-group>
              <!-- orjinal yeri -->
            </div>
            <!-- modifiye edilen yer -->
            <div v-else class="calc">
            <!--jimir3x-->
              <div
                v-if="selectedMapListItem !== null"
                class="card-list-item-collapsed card-list-item-collapsed--extra zoom   "
              >
              <!--jimir3x-->
                <div class="card-list-item-collapsed-head">
                  <a
                    @click="selectedMapListItem = null"
                    href="#"
                    class="back-content d-inline-flex align-items-center"
                  >
                    <!--<v-icon class="back-icon" icon="arrow-left"></v-icon> -->

                    <img
                      src="/img/card-detail-right-arrow.svg"
                      class="img-fluid card-detail-right-arrow"
                      alt=""
                    />
                    <span>{{ $t('mw.general.btlw') }}</span>
                  </a>
                </div>
                <div
                  v-if="userRoleType === 'SELLER'"
                  class="card-list-item-collapsed-content"
                >
                  <div class="item-detail">
                    <div class="buyer-map-card-head  d-flex w-100">
                      
                      <div class="item-head-info w-100">
                        <h3 class="text-capitalize">
                          {{ findSubItemByLang(selectedMapListItem.contents).name }}
                        </h3>

                        <nl2br
                          tag="p"
                          className="buyer-map-card-p-full"
                          :text="
                            findSubItemByLang(selectedMapListItem.contents).description
                          "
                        />
                        <div class="row buyer-map-card-industry-icon">
                          <div class="col-md-1 pr-0">
                            <industry-icon
                              :color="'red'"
                              :name="selectedMapListItem.industry.icon"
                            />
                          </div>
                          <div class="col-md-10 pl-2 text-left">
                            {{ selectedMapListItem.industry.name }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-2">
                            <p class="buyer-map-card-unit">
                              {{ $t('mw.general.unit') }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p
                              class="buyer-map-card-unit-content-inside "
                              
                            >
                              {{ selectedMapListItem.unit.name }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="buyer-map-card-unit">
                              {{ $t('mw.general.demand') }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p
                              class="buyer-map-card-unit-content-inside " 
                            >
                              {{ numberFixer(selectedMapListItem.demand) }}
                            </p>
                          </div>
                        </div>

                        <hr  class="margin-top:30px"/>

                        <!--<div
                                class="card-list-item-content-item d-flex align-items-center"
                                >
                                <p class="w-25 key">Unit</p>
                                <p class="w-75 value text-capitalize">
                                    {{ selectedMapListItem.unit.name }}
                                </p>
                                </div>
                                <div
                                class="card-list-item-content-item d-flex align-items-center"
                                >
                                <p class="w-25 key">Demand</p>
                                <p class="w-75 value text-capitalize">
                                    {{ selectedMapListItem.demand }}
                                </p>
                                </div> -->
                      </div>
                    </div>

                    <!--<div class="item-desc">
                            {{ getDefault(selectedMapListItem.contents).description }}
                            </div> -->
                  </div>
                  <!-- <div class="user-detail user-detail-seller-views">
                        <div class="d-flex align-items-center">
                        <img
                            :src="`${selectedMapListItem.member.avatar}?size=82x82`"
                            alt=""
                            class="rounded-"circle" user-img"
                        />
                        <div>
                            <a
                            href="#"
                            @click.prevent="
                                getUserProfile(selectedMapListItem.member.uid)
                            "
                            class="user-detail-name"
                            >{{ selectedMapListItem.member.name }} 1
                            {{ selectedMapListItem.member.surname }}</a
                            >
                            <span class="user-detail-title">{{
                            selectedMapListItem.member.title.name
                            }}</span>
                        </div>
                        </div>
                        <div
                        class="user-detail-linked d-flex justify-content-between align-items-center"
                        >
                        <div class="user-detail-address">
                            <v-icon :icon="['fac', 'location']"></v-icon>
                            <span
                            >{{
                                selectedMapListItem.member.locations.find((x) => x)
                                .address.city
                            }}
                            -
                            {{
                                selectedMapListItem.member.locations.find((x) => x)
                                .address.country
                            }}</span
                            >
                        </div>
                        <div class="user-detail-linked-icons">
                            <a
                            href="#"
                            @click.prevent="
                                startConversation(selectedMapListItem.member.uid)
                            "
                            >
                            <v-icon :icon="['fac', 'message']"></v-icon>
                            </a>
                            <a href="#">
                            <v-icon :icon="['fab', 'linkedin-in']"></v-icon>
                            </a>
                        </div>
                        </div>
                        <hr />
                        <div class="user-detail-props">
                        <p class="title">User Industry</p>
                        <p class="value">
                            {{ selectedMapListItem.member.industry.name }}
                        </p>
                        </div>
                        <div class="user-detail-props">
                        <p class="title">Company Name</p>
                        <p class="value">
                            {{ selectedMapListItem.member.company.name }}
                        </p>
                        </div>
                        <div class="user-detail-props">
                        <p class="title">Title</p>
                        <p class="value">
                            {{ selectedMapListItem.member.title.name }}
                        </p>
                        </div>
                        <div
                        v-if="selectedMapListItem.member.position"
                        class="user-detail-props"
                        >
                        <p class="title">Department</p>
                        <p class="value">
                            {{ selectedMapListItem.member.position.name }}
                        </p>
                        </div>
                        <div class="user-detail-props">
                        <p class="title">Years of Expreience</p>
                        <p class="value">
                            {{ selectedMapListItem.member.years_of_experience }}+
                        </p>
                        </div>
                    </div> -->

                  <div class="user-detail">
                    <div class="d-flex align-items-center">
                      <img
                        :src="`${selectedMapListItem.member.avatar}?size=82x82`"
                        width="82"
                        height="82"
                        alt=""
                        class="rounded-circle user-img"
                      />
                      <div>
                        <a
                          href="#"
                          @click.prevent="
                            getUserProfile(selectedMapListItem.member.uid)
                          "
                          class="user-detail-name"
                          >{{
                            shorter(
                              selectedMapListItem.member.name,
                              selectedMapListItem.member.surname
                            )
                          }}

                          <img
                            v-if="
                              $_.get(
                                selectedMapListItem,
                                'member.verification.icon'
                              )
                            "
                            :src="
                              $_.get(
                                selectedMapListItem,
                                'member.verification.icon'
                              )
                            "
                            class="img-fluid ml-3"
                            alt=""
                          />
                        </a>
                        <span class="user-detail-title">{{
                          selectedMapListItem.member.title.name
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="user-detail-linked d-flex justify-content-between align-items-center"
                    >
                      <div class="user-detail-address">
                        <!--<v-icon :icon="['fac', 'location']"></v-icon> -->
                        <img
                          src="/img/map-icon-card-view.svg"
                          class="img-fluid"
                          alt=""
                          style="margin-right: 10px"
                        />
                        <span
                          >{{
                            selectedMapListItem.member.locations.find(x => x)
                              .address.city || 'unnamed'
                          }}
                          -
                          {{
                            selectedMapListItem.member.locations.find(x => x)
                              .address.country
                          }}</span
                        >
                      </div>
                      <div class="user-detail-linked-icons">
                        <a
                          href="#"
                          @click.prevent="
                            startConversation(selectedMapListItem.member.uid)
                          "
                        >
                          <!--<v-icon :icon="['fac', 'message']"></v-icon> -->
                          <img
                            src="/img/card-message-map.svg"
                            class="img-fluid"
                            alt=""
                            style="margin-right: 19px"
                          />
                        </a>
                      </div>
                    </div>
                    <hr />
                    <div class="user-detail-props">
                      <p class="title">{{ $t('mw.general.industry') }}</p>
                      <p class="value">
                        {{ selectedMapListItem.member.industry.name }}
                      </p>
                    </div>
                    <div class="user-detail-props">
                      <p class="title">{{ $t('mw.general.company') }}</p>
                      <p class="value">
                        {{ selectedMapListItem.member.company.name }}
                      </p>
                    </div>
                    <div
                      v-if="selectedMapListItem.member.position"
                      class="user-detail-props"
                    >
                      <p class="title">{{ $t('mw.general.position') }}</p>
                      <p class="value">
                        {{ selectedMapListItem.member.position.name }}
                      </p>
                    </div>
                    <div class="user-detail-props">
                      <p class="title">{{ $t('mw.general.experience') }}</p>
                      <p class="value">
                        {{
                          selectedMapListItem.member.years_of_experience
                            .high === '+'
                            ? selectedMapListItem.member.years_of_experience
                                .low + '+'
                            : selectedMapListItem.member.years_of_experience
                                .low +
                              '-' +
                              selectedMapListItem.member.years_of_experience
                                .high
                        }}
                      </p>
                    </div>
                    <div class="user-detail-props">
                      <p class="title">{{ $t('mw.general.about') }}</p>
                      <nl2br
                        tag="p"
                        className="value"
                        :text="selectedMapListItem.member.about"
                      />
                    </div>
                    <hr>
                       <div class="user-detail-props">
                      <p class="title">{{ $t('profilex.info.social') }}</p>
                        <div class="social-links" style="margin-top:30px">
                            <b-row>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['LINKEDIN']"
                                >
                                    <a :href="userSocialLinks['LINKEDIN']['link']" target="_blank">
                                    <img
                                        src="/img/card-linkedin-map.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                    
                                    <img
                                        src="/img/card-linkedin-map.svg"
                                        class="img-fluid social-icon-disabled"
                                        alt=""
                                    />
                                   
                                </div>
                                </b-col>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['FACEBOOK']"
                                >
                                    <a :href="userSocialLinks['FACEBOOK']['link']" target="_blank">
                                    <img
                                        src="/img/facebook-icon.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                    
                                    <img
                                        src="/img/facebook-icon.svg"
                                        class="img-fluid social-icon-disabled"
                                        size="50"
                                        alt="facebook"
                                    />
                                    
                                </div>
                                </b-col>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['TWITTER']"
                                >
                                    <a :href="userSocialLinks['TWITTER']['link']" target="_blank">
                                    <img
                                        src="/img/twitter-icon.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                    
                                    <img
                                        src="/img/twitter-icon.svg"
                                        class="img-fluid social-icon-disabled"
                                        alt=""
                                    />
                                   
                                </div>
                                </b-col>
                            </b-row>
                       </div>
                    </div>


                  </div>
                </div>
                <div
                  v-if="userRoleType === 'BUYER'"
                  class="card-list-item-collapsed-content"
                >
                  <div class="item-detail">
                    <div class="row">
                      <div class="col-md-12 mb-4 slider-items">
                        <b-carousel
                            v-model="selectedMapListItem.slide"
                            :interval="4000"
                            :controls="true"
                            :indicators="true"
                            background="#eff2f6"
                            class="radius"
                            margin="auto"
                            vertical-align= "middle"

                             img-width="100%"
                  img-height="100%"
                  style="text-shadow: 1px 1px 2px #333; margin: auto"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                        >
                            <b-carousel-slide
                                v-for="(item, index) in selectedMapListItem.images"
                                :key="index"
                                :img-src="CDN_IMAGE_PREFIX + item "
                                class="collapsed-item-img"
                            >
                            </b-carousel-slide>
                        </b-carousel>
                      </div>
                    </div>
                    <div class="card-list-item-head mb-3 d-flex">
                      <div class="map-list-view-detail">
                        <h3 class="text-capitalize">
                          {{ findSubItemByLang(selectedMapListItem.contents).name }}
                        </h3>

                        <span class="id"
                          >{{ $t('mw.general.id') }}:
                          {{ selectedMapListItem.product_id }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="card-list-item-content card-list-item-collapsed-content-properties"
                    >
                      <div
                        class="card-list-item-content-item collapsed-item-property d-flex"
                      >
                        <p class="w-25 key">{{ $t('mw.general.price') }}</p>
                        <p class="w-75 value">
                          {{ selectedMapListItem.currency.symbol
                          }}{{ selectedMapListItem.min_price }} -
                          {{ selectedMapListItem.currency.symbol
                          }}{{ selectedMapListItem.max_price }}

                          <span class="ml-2 text-lowercase">
                            / {{ selectedMapListItem.unit.name }}</span
                          >
                        </p>
                      </div>
                      <div
                        class="card-list-item-content-item collapsed-item-property d-flex"
                      >
                        <p class="w-25 key">{{ $t('mw.general.moq') }}</p>
                        <p class="w-75 value">
                          {{ numberFixer(selectedMapListItem.moq) }}
                          <span class="ml-2 text-lowercase">
                            {{ selectedMapListItem.unit.name }}</span
                          >
                        </p>
                      </div>
                      <div
                        class="card-list-item-content-item collapsed-item-property d-flex"
                      >
                        <p class="w-25 key">{{ $t('mw.general.supply') }}</p>
                        <p class="w-75 value">
                          {{ numberFixer(selectedMapListItem.stock) }}
                          <span> / {{ $t('mw.general.month') }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="card-list-item-description">
                      <nl2br
                        tag="p"
                        :text="
                          findSubItemByLang(selectedMapListItem.contents).description
                        "
                      />
                      <p class="industry-name d-flex">
                        <industry-icon
                          style="margin-right: 10px"
                          :color="'red'"
                          :name="selectedMapListItem.industry.icon"
                        />
                        {{ selectedMapListItem.industry.name }}
                      </p>
                    </div>

                    <hr />
                    <!--<div class="item-desc">
                            {{ getDefault(selectedMapListItem.contents).description }}
                            </div> -->
                  </div>
                  <div class="user-detail">
                    <div class="d-flex align-items-center">
                      <img
                        :src="`${selectedMapListItem.member.avatar}?size=82x82`"
                        alt=""
                        width="82"
                        height="82"
                        class="rounded-circle user-img"
                      />
                      <div>
                        <a
                          href="#"
                          @click.prevent="
                            getUserProfile(selectedMapListItem.member.uid)
                          "
                          class="user-detail-name"
                          >{{ selectedMapListItem.member.name }}
                          {{ selectedMapListItem.member.surname }}

                          <img
                            v-if="
                              $_.get(
                                selectedMapListItem,
                                'member.verification.icon'
                              )
                            "
                            :src="
                              $_.get(
                                selectedMapListItem,
                                'member.verification.icon'
                              )
                            "
                            class="img-fluid ml-3"
                            alt=""
                          />
                        </a>
                        <span class="user-detail-title">{{
                          selectedMapListItem.member.title.name
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="user-detail-linked d-flex justify-content-between align-items-center"
                    >
                      <div class="user-detail-address">
                        <!--<v-icon :icon="['fac', 'location']"></v-icon> -->
                        <img
                          src="/img/map-icon-card-view.svg"
                          class="img-fluid"
                          alt=""
                          style="margin-right: 10px"
                        />
                        <span
                          >{{
                            selectedMapListItem.member.locations.find(x => x)
                              .address.city || 'unnamed'
                          }}
                          -
                          {{
                            selectedMapListItem.member.locations.find(x => x)
                              .address.country
                          }}</span
                        >
                      </div>
                      <div class="user-detail-linked-icons">
                        <a
                          href="#"
                          @click.prevent="startConversation(selectedMapListItem.member.uid)"
                        >
                          <img
                            src="/img/card-message-map.svg"
                            class="img-fluid"
                            alt=""
                            style="margin-right: 19px"
                          />
                        </a>
                     
                      </div>
                    </div>
                    <hr />
                    <div class="user-detail-props">
                      <p class="title">{{ $t('mw.general.industry') }}</p>
                      <p class="value">
                        {{ selectedMapListItem.member.industry.name }}
                      </p>
                    </div>
                    <div class="user-detail-props">
                      <p class="title">{{ $t('mw.general.company') }}</p>
                      <p class="value">
                        {{ selectedMapListItem.member.company.name }}
                      </p>
                    </div>
                    <div
                      v-if="selectedMapListItem.member.position"
                      class="user-detail-props"
                    >
                      <p class="title">{{ $t('mw.general.position') }}</p>
                      <p class="value">
                        {{ selectedMapListItem.member.position.name }}
                      </p>
                    </div>
                    <div class="user-detail-props">
                      <p class="title">{{ $t('mw.general.experience') }}</p>
                      <p class="value">
                        {{
                          selectedMapListItem.member.years_of_experience
                            .high === '+'
                            ? selectedMapListItem.member.years_of_experience
                                .low + '+'
                            : selectedMapListItem.member.years_of_experience
                                .low +
                              '-' +
                              selectedMapListItem.member.years_of_experience
                                .high
                        }}
                      </p>
                    </div>
                    <div class="user-detail-props">
                      <p class="title">{{ $t('mw.general.about') }}</p>
                      <nl2br
                        tag="p"
                        className="value"
                        :text="selectedMapListItem.member.about"
                      />
                    </div>
<hr>
                    <div class="user-detail-props">
                      <p class="title">{{ $t('profilex.info.social') }}</p>
                       <div class="social-links" style="margin-top:30px">
                            <b-row>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['LINKEDIN']"
                                >
                                    <a :href="userSocialLinks['LINKEDIN']['link']" target="_blank">
                                    <img
                                        src="/img/card-linkedin-map.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                    
                                    <img
                                        src="/img/card-linkedin-map.svg"
                                        class="img-fluid social-icon-disabled"
                                        alt=""
                                    />
                                   
                                </div>
                                </b-col>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['FACEBOOK']"
                                >
                                    <a :href="userSocialLinks['FACEBOOK']['link']" target="_blank">
                                    <img
                                        src="/img/facebook-icon.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                    
                                    <img
                                        src="/img/facebook-icon.svg"
                                        class="img-fluid social-icon-disabled"
                                        size="50"
                                        alt="facebook"
                                    />
                                    
                                </div>
                                </b-col>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['TWITTER']"
                                >
                                    <a :href="userSocialLinks['TWITTER']['link']" target="_blank">
                                    <img
                                        src="/img/twitter-icon.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                    
                                    <img
                                        src="/img/twitter-icon.svg"
                                        class="img-fluid social-icon-disabled"
                                        alt=""
                                    />
                                   
                                </div>
                                </b-col>
                            </b-row>
                       </div>
                    </div>

                     
                  </div>
                </div>
              </div>
            </div>
            <!-- HARITA -->
          </div>
          <div class="col-9 flex-grow-1 pr-0 pl-2" style="margin-top:18px">
            <div class="map-card " style="height:99.2%; width:98%;">
              <div class="map-loading" v-if="loading">
                <!-- <loading :loading="loading"></loading> -->
              </div>
              <mapbox-map
               class="calc"
                ref="matchmap"
                :scrollZoom="false"
                v-if="location.center"
                access-token="pk.eyJ1IjoiY2FudTkiLCJhIjoiY2tkYW5jNnZjMGpqbzM0cnhheGlhMDJjayJ9.TynY2B9VpBjKI5GOXtSNMA"
                map-style="mapbox://styles/canu9/ckdanejg91d7o1iqathzm7n5l"
                :center="location.center"
                :zoom="zoomForMap"
                @mb-created="created"
                :minZoom="3"
                :maxZoom="51"
              >
                <mapbox-navigation-control position="top-right" />
                <mapbox-cluster
                  :clusterRadius="100"
                  :clusterMaxZoom="20"
                  :data="{ type: 'FeatureCollection', features: markers }"
                  :unclusteredPointLayerType="'symbol'"
                  :unclusteredPointLayout="{
                    'icon-image': [
                      'case',
                      ['==', ['get', 'similar'], true],
                      $options.group,
                      ['get', 'avatar'],
                    ],
                    'icon-size': 0.30,
                  }"
                  :unclusteredPointPaint="{}"
                  :clustersPaint="boyali"
                  @mb-feature-click="featureClick"
                />
                <mapbox-marker :lng-lat="location.center"  >

                  <img style="opacity:0.2" src="/img/guy1.svg" alt="">
                   <!-- <svg style="width:24px;height:24px; margin-bottom:40px; z-index:-999" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2C15.9 2 19 5.1 19 9C19 14.2 12 22 12 22S5 14.2 5 9C5 5.1 8.1 2 12 2M12 4C9.2 4 7 6.2 7 9C7 10 7 12 12 18.7C17 12 17 10 17 9C17 6.2 14.8 4 12 4M12 11.5L14.4 13L13.8 10.2L16 8.3L13.1 8.1L12 5.4L10.9 8L8 8.3L10.2 10.2L9.5 13L12 11.5Z" /> 
</svg>-->
                </mapbox-marker> 
                <mapbox-popup
                  v-if="feature"
                  class="zoom"
                  :lngLat="feature.geometry.coordinates.slice()"
                  :closeButton="false"
                  :closeOnMove="true"
                  @mb-close="onPopupClose"
                  maxWidth="379px"
                  anchor="left"
                >
                  <map-view-component :member="feature" />
                </mapbox-popup>
                <mapbox-popup
                  v-if="similars"
                  class="zoom"
                  :lngLat="similarsLngLat"
                  :closeButton="false"
                  :closeOnMove="true"
                  @mb-close="onSimilarsPopupClose"
                  anchor="right"
                  maxWidth="410px"
                >
                  <duplicate-location-popup
                    @featureClick="duplicateFeatureClick"
                    :features="similars"
                  />
                </mapbox-popup>
              </mapbox-map>
            </div>
          </div>
        </div>
      </div>

      <!--jimir3x-->
      <div
        v-show="view !== 'map'"
        class="dashboard-content-list-card calc"
        style="margin-top: 20px;"
        tabindex="0"
      >
        <!--jimir3x-->
        <card-view
          ref="cardView"
          @totalCount="getTotal"
          v-show="view === 'card'"
        ></card-view>
        <list-view
          @totalCount="getTotal"
          ref="listView"
          v-show="view === 'list'"
        ></list-view>
      </div>



      <!-- distance modal -->
      <b-modal
        centered
        @hidden="closeDistanceModal"
        :hide-footer="true"
        id="distanceSelectModal"
        size="sm"
        :title="$t('minimap.distance')"
      >
        <template #modal-header-close>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="24" 
                height="24" 
                viewBox="0 0 24 24"
            >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#292c34">
                </path>
            </svg>
        </template>
        <div class="mb-5 zoom" v-if="!upgradePlan">   
          <v-icon :icon="['fac', 'location']" />
          <span v-if="!changeLocationData.place_name" class="ml-2">
              {{ activeLocation.address.city || activeLocation.address.country + ' unnamed' || 'unnamed' }}
          </span>
          <span v-if="changeLocationData.place_name" class="ml-2">
            {{ changeLocationData.place_name }}
          </span>
          <a
            @click.prevent="openChangeLocation()"
            href="#"
            class="ml-3 location-secondary"
           
          >
            {{ $t('minimap.regionsrch') }}
          </a>
          <a
            :class="[
              'ml-3',
              activeLocationIndex !== index ? 'location-secondary' : '',
              'float-right',
            ]"
            v-for="(location, index) in locations"
            :key="index"
            @click="setActiveLocation(index)"
             style="cursor:pointer; text-decoration:none;"
              class="ml-3 location-secondary"
        
          >
            {{ location.address.city ? location.address.city :  location.address.country }}
          </a>
        </div>

        <div class="apply-to-change-plan zoom" v-if="upgradePlan">
          <h1>{{ $t('minimap.upgradeplan') }}</h1>
          <div class="hr-white-style"></div>
          <h2>{{ $t('minimap.p1') }}</h2>

          <h3>
            <svg style="width:24px;height:24px; color:#198cff;" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10.6 9.6L9 15L7.4 9.6L2 8L7.4 6.4L9 1L10.6 6.4L16 8L10.6 9.6M17 14.2L21 12L18.8 16L21 20L17 17.8L13 20L15.2 16L13 12L17 14.2M10 16L8.3 19L10 22L7 20.3L4 22L5.7 19L4 16L7 17.7L10 16" />
</svg>

            {{ $t('minimap.p2') }}</h3>

          <h3>
             <svg style="width:24px;height:24px; color:#198cff;" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10.6 9.6L9 15L7.4 9.6L2 8L7.4 6.4L9 1L10.6 6.4L16 8L10.6 9.6M17 14.2L21 12L18.8 16L21 20L17 17.8L13 20L15.2 16L13 12L17 14.2M10 16L8.3 19L10 22L7 20.3L4 22L5.7 19L4 16L7 17.7L10 16" />
</svg>
            
            {{ $t('minimap.p3') }}</h3>

          <h3 class="last-child-h3">

             <svg style="width:24px;height:24px; color:#198cff;" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10.6 9.6L9 15L7.4 9.6L2 8L7.4 6.4L9 1L10.6 6.4L16 8L10.6 9.6M17 14.2L21 12L18.8 16L21 20L17 17.8L13 20L15.2 16L13 12L17 14.2M10 16L8.3 19L10 22L7 20.3L4 22L5.7 19L4 16L7 17.7L10 16" />
</svg>

            {{ $t('minimap.p4') }}
          </h3>
          <div class="row justify-content-space-between">
            <div class="col-md-3">
              <a @click="upgradePlanCancel" class="cancel-apply-plan">{{
                $t('minimap.cancel')
              }}</a>
            </div>
            <div class="col-md-9 d-flex justify-content-flex-end ">
              <router-link
                to="/app/profile/settings/plan"
                class=" apply-upgrade-plan-button"
                >{{ $t('minimap.upgradeplan') }}</router-link
              >
            </div>
          </div>
        </div>

        <mapbox-map
          :scrollZoom="false"
          v-if="changeLocationData.center"
          style="box-shadow: none !important"
          access-token="pk.eyJ1IjoiY2FudTkiLCJhIjoiY2tkYW5jNnZjMGpqbzM0cnhheGlhMDJjayJ9.TynY2B9VpBjKI5GOXtSNMA"
          map-style="mapbox://styles/canu9/ckdanejg91d7o1iqathzm7n5l"
          :center="changeLocationData.center"
          :zoom="zoomForMap"
          :minZoom="1"
          :maxZoom="51"
          @mb-created="createdModalMap"
          class="zoom minimap"
          
        >
          <mapbox-geocoder
            ref="geo"
            @mb-result="gecoderSubmit"
            v-if="isOpenChangeLocation"
            :placeholder="
              changeLocationData.place_name || 'Enter City or Country Name'
            "
            :minLength="3"
            access-token="pk.eyJ1IjoiY2FudTkiLCJhIjoiY2tkYW5jNnZjMGpqbzM0cnhheGlhMDJjayJ9.TynY2B9VpBjKI5GOXtSNMA"
            @mb-created="instanceGeo"
          />
          <!-- <mapbox-navigation-control position="bottom-right" /> -->
          <mapbox-marker v-if="test" :lng-lat="changeLocationData.center" />
        </mapbox-map>
        <div class="d-flex distance-slider align-items-center ">
          <span class="icon">
            <svg style="width:32px;height:32px; color:#c5c5c5" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
</svg>
            <!-- <v-icon icon="home"></v-icon> -->
          </span>
          <vue-slider
            :tooltip="'always'"
            v-if="openModal"
            :max="sliderMaxDistance"
            ref="slider"
            class="w-100"
            :class="{ 'grey-grey': greyDistance }"
            :tooltip-formatter="formatter"
            v-model="distanceRange"
            :tooltip-placement="['top']"
          ></vue-slider>

          <span class="icon">
            <svg style="width:32px;height:32px; color:#c5c5c5" viewBox="0 0 24 24">
    <path fill="currentColor" d="M16.5,12A2.5,2.5 0 0,0 19,9.5A2.5,2.5 0 0,0 16.5,7A2.5,2.5 0 0,0 14,9.5A2.5,2.5 0 0,0 16.5,12M9,11A3,3 0 0,0 12,8A3,3 0 0,0 9,5A3,3 0 0,0 6,8A3,3 0 0,0 9,11M16.5,14C14.67,14 11,14.92 11,16.75V19H22V16.75C22,14.92 18.33,14 16.5,14M9,13C6.67,13 2,14.17 2,16.5V19H9V16.75C9,15.9 9.33,14.41 11.37,13.28C10.5,13.1 9.66,13 9,13Z" />
</svg>
            <!-- <v-icon :icon="['fac', 'road']"></v-icon> -->
          </span>
        </div>
        <button class="btn btn-primary w-100 my-2 zoom" @click="applyDistance">
          {{ $t('buttons.apply') }}
        </button>
        <!-- <button
          @click="resetDistance"
          v-if="isApplyDistance"
          class="btn btn-outline-primary w-100 my-3 "
        >
          {{ $t('buttons.reset') }}
        </button> -->
      </b-modal>
      <!-- distance modal -->

       <!-- filters modal -->

      <sweet-modal id="filterModal"
      ref="filtersModal" 
      class="filter-modal"
      >
      <h1>{{$t("General.filters")}}</h1>
      
          <b-row  class="filter-modal">
              <b-col lg="12" md="12" sm="12" xs="12" class="align-items-left pb-2 "> 
                <b-row class="">  
                    <b-col lg="3" md="6" sm="12" xs="12">
                        <div class="more-filters-item"  style="height:100vh; border-radius:20px;">
                            <treeselect style="text-transform:capitalize;"
                                v-if="userRole === 'BUYER'"
                                v-model="filters.items"
                                :multiple="true"
                                :options="formatItems(this.minquiries)"
                                :placeholder="$t('headermenu.myrequests')"
                                :alwaysOpen="true"
                            >
                            </treeselect>
                            <treeselect style="text-transform:capitalize;"
                                v-else
                                v-model="filters.items" 
                                :multiple="true"
                                :options="formatItems(this.mproducts)"
                                 :placeholder="$t('headermenu.myquotes')"
                                :alwaysOpen="true"
                            >
                            </treeselect>
                        </div>
                    </b-col>
                    <b-col lg="2" md="6" sm="12" xs="12">
                        <div class="more-filters-item"  style="height:100vh; border-radius:20px;">

                       
                        <treeselect
                            ref="ts-pi"
                            v-model="filters.productIndustry"
                            :multiple="true"
                            :options="fetchFilterData.productIndustires"
                            :placeholder="$t('mw.filter.f1')"
                            :alwaysOpen="true"
                            :load-options="loadOptions"
                            @open="treeSelectOpen"
                        >
                        </treeselect>
                       
                       
                        </div>
                    </b-col>
                    <b-col lg="2" md="6" sm="12" xs="12">
                        <div class="more-filters-item">
                        <treeselect
                            ref="ts-ui"
                            v-model="filters.selectUserIndustry"
                            :multiple="true"
                            :options="fetchFilterData.userIndustries"
                            :placeholder="$t('mw.filter.f2')"
                            :alwaysOpen="true"
                            @open="treeSelectOpen"
                        >
                        </treeselect>
                        </div>
                    </b-col>

                    <b-col lg="2" md="6" sm="12" xs="12">
                        <div class="more-filters-item">
                        <treeselect
                            ref="ts-st"
                            :disabled="false"
                            v-model="filters.selectTitles"
                            :multiple="true"
                            :options="fetchFilterData.titles"
                            :placeholder="$t('mw.filter.f3')"
                            :alwaysOpen="true"
                            @open="treeSelectOpen"
                        >
                        </treeselect>
                        </div>
                    </b-col>

                    <b-col lg="2" md="6" sm="12" xs="12">
                        <div class="more-filters-item">
                        <treeselect
                            ref="ts-d"
                            v-model="filters.selectDeparments"
                            :multiple="true"
                            :options="fetchFilterData.tmp"
                            :placeholder="$t('mw.filter.f4')"
                            :alwaysOpen="true"
                            @open="treeSelectOpen"
                        >
                        </treeselect>
                        </div>
                    </b-col>
                </b-row>
                <!-- <b-row
            class="selected-filter-items-modal px-5"
            v-show="
                (Array.isArray(filters.selectUserIndustry) &&
                filters.selectUserIndustry.length) ||
                (Array.isArray(filters.productIndustry) &&
                    filters.productIndustry.length) ||
                (Array.isArray(filters.selectTitles) &&
                    filters.selectTitles.length) ||
                (Array.isArray(filters.selectDeparments) &&
                    filters.selectDeparments.length)
            "
         >
          <a
            v-for="(selectedIndustryItem, index) in filters.selectUserIndustry"
            :key="'ii-' + index"
            class="mr-3 selected-industry-item"
          >
            #{{
              fetchFilterData.userIndustries.find(
                x => x.id == selectedIndustryItem
              ).label
            }}
            <img
              @click="removeFromSelectedIndustry(index)"
              src="/img/cross.svg"
              alt=""
            />
          </a>

          <a
            v-for="(selectedProductIndustryItem,
            index) in filters.productIndustry"
            :key="'pi-' + index"
            class="mr-3 selected-industry-item"
          >
            #{{
              fetchFilterData.productIndustires.find(
                x => x.id == selectedProductIndustryItem
              ).label
            }}
            <img
              @click="removeFromProductIndustry(index)"
              src="/img/cross.svg"
              alt=""
            />
          </a>

          <a
            v-for="(selectedTitlesItem, index) in filters.selectTitles"
            :key="'ti-' + index"
            class="mr-3 selected-industry-item"
          >
            #{{
              fetchFilterData.titles.find(x => x.id == selectedTitlesItem).label
            }}
            <img
              @click="removeFromselectTitles(index)"
              src="/img/cross.svg"
              alt=""
            />
          </a>

          <a
            v-for="(selectedDeparmentsItem, index) in filters.selectDeparments"
            :key="'di-' + index"
            class="mr-3 selected-industry-item"
          >
            #{{
              fetchFilterData.tmp.find(x => x.id == selectedDeparmentsItem)
                .label
            }}
            <img
              @click="removeFromDepermants(index)"
              src="/img/cross.svg"
              alt=""
            />
          </a>
               </b-row> -->

            </b-col>
          </b-row>
          
      </sweet-modal>
      <!-- <locationReplaceInfo /> -->
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import cardView from '../../components/app/matching/views/card';
import listView from '../../components/app/matching/views/list';
import MapList from '@/graphql/me/matching/buyer/Map.gql';
import MapListSeller from '@/graphql/me/matching/seller/Map.gql';
// import MapPopup from "@/components/app/matching/component/MapPopupComponent";
import MapViewComponent from '@/components/app/matching/component/MapViewComponent';
import DuplicateLocationPopup from '@/components/app/matching/component/DuplicateLocationPopup';
// import MapboxPopup from "@studiometa/vue-mapbox-gl/dist/components/MapboxPopup";
import loading from '@/components/global/loading/index';
import noAvatar from '@/assets/img/no-avatar.png';
// çoklu icon
import group from '@/assets/img/group.png';
import filterDataQueryBuyer from '@/graphql/me/matching/buyer/filterData.gql';
import filterDataQueryBuyerSeller from '@/graphql/me/matching/seller/filterData.gql';
import LOAD_MY_PRODUCTS from '@/graphql/me/getProducts.gql';
import LOAD_MY_INQUIRIES from '@/graphql/me/getInquiries.gql';
// import locationReplaceInfo from "@/components/app/matching/component/locationReplaceInfo";
import _ from 'lodash';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { mapGetters } from 'vuex';
import * as turf from '@turf/turf';
export default {
  group: group,
  components: {
    Treeselect,
    cardView,
    listView,
    VueSlider,
    loading,
    MapViewComponent,
    DuplicateLocationPopup, 
    DuplicateLocationPopup,
    // locationReplaceInfo,
  },
  data() {
    return {
      mapIcon: 'http://app.connectter.com/img/map-icon-header-white.svg',
      langs: ['tr', 'en'],
      boyali: {
        'circle-color': '#0073e4',
        'circle-radius': 20,
        'circle-stroke-width': 1.8,
        'circle-stroke-color': '#ffffff'
      },
      searched: false,
      activeLocationIndex: -1,
      upgradePlan: false,
      plans: [],
      similars: null,
      totalMember: 0,
      mapList: null,
      search: null,
      isOpenChangeLocation: false,
      test: true,
      openTooltip: false,
      selectedTitles: false,
      disableNext: false,
      disablePrev: true,
      currentPage: 1,
      distanceRange: 0,
      selectedMapListItem: null,
      cursors: [],
      totalData: 0,
      mapItemList: [],
      modalMap: null,
      loading: true,
      location: {},
      markers: [],
      km: 50,
      distance: null,
      zoomForMap: 4,
      tmpViewfilter: {},
      view: 'map',
      listMap: {
        limit: 100,
        orders: [
          {
            field: '_score',
            sort: 'DESC',
          },
          {
            field: 'id',
            sort: 'DESC',
          },
        ],
        cursor: null,
        filters: {
          bound: {
            top_right: {
              lat: null,
              lon: null,
            },

            bottom_left: {
              lat: null,
              lon: null,
            },
          },
        },
      },
      openFilters: false,
      totalCount: null,
      fetchData: {
        userIndustry: [
          {
            name: 'deneme 2',
            id: 'deneme 2',
          },
          {
            name: 'deneme 3',
            id: 'deneme 3',
          },
          {
            name: 'deneme 4',
            id: 'deneme 4',
          },
        ],
      },
      openModal: false,
      isApplyDistance: false,
      errorDistance: false,
      changeLocationData: {
        center: [],
        place_name: null,
      },
      filters: {
        distance: null,
        items: [],
        productIndustry: [],
        selectDeparments: [],
        selectTitles: [],
        selectUserIndustry: [],
      },
      sort: {
        value: { id: 1, name: 'Price Low to High' },
      },
      sortFilterData: [
        {
          id: 1,
          name: 'Price Low to High',
        },
        {
          id: 2,
          name: 'Price High to Low',
        },
      ],
      fetchFilterData: {
        userIndustries: [],
        productIndustires: [],
        titles: [],
        positions: [],
        tmp: [],
      },
      greyDistance: false,
      feature: null,
      minquiries: [],
      mproducts: []
    };
  },
  computed: {
     excludedItems() {
        let items = this.userRoleType === 'SELLER' ? this.mproducts : this.minquiries;
        if (_.size(items) === _.size(this.filters.items) || _.size(this.filters.items) === 0) {
            return null;
        }

        return _.map(_.filter(items, i => _.includes(this.filters.items, i.id) === false), x => x.id);
    },
    userRole() {
        return window.localStorage.getItem('user_type');
    },
    ...mapGetters(['GET_USER_DISTANCE_UNIT']),
    similarsLngLat() {
      let point = _.get(this.similars, '[0].member.locations[0].point');
      if (point) {
        return [point.lon, point.lat];
      }

      return null;
    },
    formatter() {
      if (this.GET_USER_DISTANCE_UNIT == 'mi') {
        return value => `${value}mi`;
      } else if (this.GET_USER_DISTANCE_UNIT == 'km') {
        return value => `${value}km`;
      } else {
        return value => `${value}km`;
      }
    },
    totalPage() {
      return Math.ceil(this.totalData / this.listMap.limit);
    },
    defaultDistance() {
      if (this.GET_USER_DISTANCE_UNIT == 'mi') {
        return Math.round(
          this.GET_USER_INFO.group.permissions.default_distance * 0.62137
        );
      } else if (this.GET_USER_DISTANCE_UNIT == 'km') {
        return this.GET_USER_INFO.group.permissions.default_distance;
      } else {
        return this.GET_USER_INFO.group.permissions.default_distance;
      }
    },
    maxDistance() {
      if (this.GET_USER_DISTANCE_UNIT == 'mi') {
        return Math.round(
          this.GET_USER_INFO.group.permissions.distance * 0.62137
        );
      } else if (this.GET_USER_DISTANCE_UNIT == 'km') {
        return this.GET_USER_INFO.group.permissions.distance;
      } else {
        return this.GET_USER_INFO.group.permissions.distance;
      }
    },
    sliderMaxDistance() {
      if (this.GET_USER_DISTANCE_UNIT == 'mi') {
        return 1550;
      } else if (this.GET_USER_DISTANCE_UNIT == 'km') {
        return 2500;
      } else {
        return 2500;
      }
    },
    isDisablePrev() {
      if (this.currentPage <= 1) {
        return true;
      }
      return false;
    },
    isDisableNext() {
      if (this.currentPage >= this.totalPage) {
        return true;
      }
      return false;
    },
    activeLocation() {
      if (this.activeLocationIndex === -1) {
        return _.find(this.GET_USER_INFO.locations, x => x.default);
      }

      return this.GET_USER_INFO.locations[this.activeLocationIndex];
    },
    locations() {
        return this.GET_USER_INFO?.locations || [];
    },
    userSocialLinks() {
        if (!_.has(this, 'selectedMapListItem.member')) {
            return {};
        }

        let accounts = _.get(this, 'selectedMapListItem.member.social_accounts', []);
        let filtered = _.filter(accounts, sa => !_.isNil(sa.link));
        return _.keyBy(filtered, 'type');
    },
  },
  watch: {
    view(val) {
      this.distance = this.calc(
        this.GET_USER_DISTANCE_UNIT,
        'km',
        this.distanceRange
      );
      
      
      if (val === 'list') {
        this.$refs.listView._data.cursor = null;
        this.$refs.listView._data.cursors = [];
        this.$refs.listView._data.currentPage = 1;
        if (this.userRoleType === 'BUYER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
            location: {
                lon: this.changeLocationData.center[0] || this.location.center[0],
                lat: this.changeLocationData.center[1] || this.location.center[1]
            }
          };
          this.$refs.listView._data.cardFilterBuyer.filter = filter;
          this.$refs.listView.getList();
        }
        if (this.userRoleType === 'SELLER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
            location: {
                lon: this.changeLocationData.center[0] || this.location.center[0],
                lat: this.changeLocationData.center[1] || this.location.center[1]
            }
          };
          this.$refs.listView._data.sellerFilterList.filter = filter;
          this.$refs.listView.getListBySeller();
        }
      }
      if (val === 'card') {
        this.$refs.cardView._data.cursor = null;
        this.$refs.cardView._data.cursors = [];
        this.$refs.cardView._data.currentPage = 1;
        if (this.userRoleType === 'BUYER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
            location: {
                lon: this.changeLocationData.center[0] || this.location.center[0],
                lat: this.changeLocationData.center[1] || this.location.center[1]
            }
          };
          this.$refs.cardView._data.cardFilterBuyer.filter = filter;
          this.$refs.cardView.getList();
        }
        if (this.userRoleType === 'SELLER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
            location: {
                lon: this.changeLocationData.center[0] || this.location.center[0],
                lat: this.changeLocationData.center[1] || this.location.center[1]
            }
          };
          this.$refs.cardView._data.sellerFilterList.filter = filter;
          this.$refs.cardView.getListBySeller();
        }
      }
      if (val === 'map') {
        this.listMap.cursor = null;
        this.currentPage = 1;
        let industry_id = this.filters.productIndustry;
        let position_id = this.filters.selectDeparments;
        let title_id = this.filters.selectTitles;
        let member_industry_id = this.filters.selectUserIndustry;
        this.listMap.filters = {
          ...this.listMap.filters,
          excludeds: this.excludedItems,
          location: {
            lon: this.changeLocationData.center[0] || this.location.center[0],
            lat: this.changeLocationData.center[1] || this.location.center[1]
          },
          distance: this.distance,
          industry_id,
          member_industry_id,
          title_id,
          position_id,
        };
        
        if (this.userRoleType === 'BUYER') {
          this.fetchMapList();
        }
        if (this.userRoleType === 'SELLER') {
          this.fetchMapListSeller();
        }
      }

      localStorage.setItem('activeView', val);
    },
    GET_USER_DISTANCE_UNIT() {
      this.distanceRange = this.defaultDistance;
    },
    distanceRange(val, oldVal) {
      if (this.distanceRange > this.maxDistance) {
        this.errorDistance = true;
        this.greyDistance = true;
      }

      if (this.distanceRange < this.maxDistance) {
        this.greyDistance = false;
      }

      if (this.modalMap && this.changeLocationData.center.length > 0) {
        this.modalMap
          .getSource('polygon')
          .setData(
            this.mapDrawPolygon(
              this.changeLocationData.center,
              this.calc(this.GET_USER_DISTANCE_UNIT, 'km', this.distanceRange)
            ).data
          );
          
        let bbox = turf.bbox(
            this.mapDrawPolygon(
              this.changeLocationData.center,
              this.calc(this.GET_USER_DISTANCE_UNIT, 'km', this.distanceRange)
            ).data
        );

        this.modalMap.fitBounds(bbox, { padding: 10 });
      }

      if (this.modalMap && this.changeLocationData.center.length === 0) {
        this.modalMap
          .getSource('polygon')
          .setData(
            this.mapDrawPolygon(
              [this.activeLocation.point.lon, this.activeLocation.point.lat],
              this.calc(this.GET_USER_DISTANCE_UNIT, 'km', this.distanceRange)
            ).data
          );

        let bbox = turf.bbox(
            this.mapDrawPolygon(
              [this.activeLocation.point.lon, this.activeLocation.point.lat],
              this.calc(this.GET_USER_DISTANCE_UNIT, 'km', this.distanceRange)
            ).data
        );

        this.modalMap.fitBounds(bbox, { padding: 10 });
      }
    },
    filters: {
      handler() {
        this.$nextTick(
            () => this.applyFilter()
        );
      },
      deep: true
    }
  },
  methods: {
    formatItems(items) {
        return _.map(
            items, 
            item => (
                { 
                    id: item.id, 
                    label: `${this.getDefault(item.contents).name}`
                }
            )
        );
    },
    calc(from, to, value) {
      if (from === 'mi' && to === 'km') {
        return Math.round(value / 0.62);
      } else if (from === 'mi' && to === 'mi') {
        return value;
      } else if (from === 'km' && to === 'mi') {
        return Math.round(value * 0.62);
      } else {
        return value;
      }
    },
    upgradePlanCancel() {
      this.upgradePlan = false;
    },
    upgradeRoute() {
      this.$router.push('/app/dashboard');
    },
    removeFromSelectedIndustry(index) {
      let removedIndustryArray = this.filters.selectUserIndustry;
      let removingItem = removedIndustryArray.indexOf(
        this.filters.selectUserIndustry[index]
      );

      removedIndustryArray.splice(removingItem, 1);
    },
    removeFromProductIndustry(index) {
      let removedProductArray = this.filters.productIndustry;
      let removingItem = removedProductArray.indexOf(
        this.filters.productIndustry[index]
      );

      removedProductArray.splice(removingItem, 1);
    },
    removeFromselectTitles(index) {
      let removedSelectArray = this.filters.selectTitles;
      let removingItem = removedSelectArray.indexOf(
        this.filters.selectTitles[index]
      );

      removedSelectArray.splice(removingItem, 1);
    },
    removeFromDepermants(index) {
      let removedDepermantArray = this.filters.selectDeparments;
      let removingItem = removedDepermantArray.indexOf(
        this.filters.selectDeparments[index]
      );

      removedDepermantArray.splice(removingItem, 1);
    },
    instanceGeo(val) {},
    openChangeLocation() {
      if (this.GET_USER_INFO.group.permissions.replace_location) {
        return (this.isOpenChangeLocation = !this.isOpenChangeLocation);
      }

      this.isOpenChangeLocation = false;
      this.upgradePlan = true;
    },
    gecoderSubmit(result) {
      this.changeLocationData.center = result.result.center;
      this.changeLocationData.place_name = result.result.place_name;
      this.test = false;
      this.modalMap.flyTo({ center: this.location.center, essential: true });
      this.test = true;
      this.location.center = result.result.center;
    },
    closeTitleSelectBox(value) {
      let positions = value
        .map(({ id, name }) =>
          this.fetchFilterData.tmp
            .filter(position => position.title_id === id)
            .map(x => ({ ...x, title_name: name }))
        )
        .flat();
      this.fetchFilterData.positions = positions;
      if (positions.length === 0) {
        return (this.selectedTitles = false);
      }
      this.selectedTitles = true;
    },
    changeSort(option) {
      this.listMap.orders.find(item => item.field === 'price').sort =
        option.id === 1 ? 'DESC' : 'ASC';
      this.listMap.cursor = null;
      this.currentPage = 1;
      this.fetchMapList();
    },
    nextPage() {
      this.currentPage++;

      if (this.userRoleType === 'BUYER') {
        this.fetchMapList();
      }
      if (this.userRoleType === 'SELLER') {
        this.fetchMapListSeller();
      }
    },
    prevPage() {
      this.currentPage--;

      let x = this.cursors[this.cursors.length - 3];
      this.cursors.splice(this.cursors.length - 3);
      this.listMap.cursor = x;
      if (this.userRoleType === 'BUYER') {
        this.fetchMapList();
      }
      if (this.userRoleType === 'SELLER') {
        this.fetchMapListSeller();
      }
    },
    applyFilter() {
      this.distance = this.calc(
        this.GET_USER_DISTANCE_UNIT,
        'km',
        this.distanceRange
      );
      if (this.view === 'list') {
        this.$refs.listView._data.cursor = null;
        this.$refs.listView._data.cursors = [];
        this.$refs.listView._data.currentPage = 1;
        if (this.userRoleType === 'BUYER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
          };
          this.$refs.listView._data.cardFilterBuyer.filter = filter;
          this.$refs.listView.getList();
        }
        if (this.userRoleType === 'SELLER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
          };
          this.$refs.listView._data.sellerFilterList.filter = filter;
          this.$refs.listView.getListBySeller();
        }
      }
      if (this.view === 'card') {
        this.$refs.cardView._data.cursor = null;
        this.$refs.cardView._data.cursors = [];
        this.$refs.cardView._data.currentPage = 1;
        if (this.userRoleType === 'BUYER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
          };
          this.$refs.cardView._data.cardFilterBuyer.filter = filter;
          this.$refs.cardView.getList();
        }
        if (this.userRoleType === 'SELLER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
          };
          this.$refs.cardView._data.sellerFilterList.filter = filter;
          this.$refs.cardView.getListBySeller();
        }
      }
      if (this.view === 'map') {
        this.listMap.cursor = null;
        this.currentPage = 1;
        let industry_id = this.filters.productIndustry;
        let position_id = this.filters.selectDeparments;
        let title_id = this.filters.selectTitles;
        let member_industry_id = this.filters.selectUserIndustry;
        this.listMap.filters = {
          ...this.listMap.filters,
          excludeds: this.excludedItems,
          distance: this.distance,
          industry_id,
          member_industry_id,
          title_id,
          position_id,
        };

        // let filter = {
        //     member_industry_id: this.filters.selectUserIndustry,
        //     industry_id: this.filters.productIndustry,
        //     title_id: this.filters.selectTitles,
        //     position_id: this.filters.selectDeparments,
        //     distance: this.distance || null,
        // };

        if (this.userRoleType === 'BUYER') {
          this.fetchMapList();
          //   this.getFilterData(filter);
        }
        if (this.userRoleType === 'SELLER') {
          this.fetchMapListSeller();
          //   this.getFilterDataBySeller(filter);
        }
      }
      //console.log(distance, productIndustires, selectDeparments, selectTitles, selectUserIndustry)
    },
    resetFilter(change = true) {
      this.isApplyDistance = false;
      this.value = 0;
      this.distanceRange = 0;
      this.currentPage = 1;
      this.listMap.cursor = null;
      this.distance = 0;
      this.filters = {
        distance: 0,
        productIndustry: [],
        selectDeparments: [],
        selectTitles: [],
        selectUserIndustry: [],
      };
      if (this.view === 'card') {
        this.$refs.cardView.resetComponent();
      }
      if (this.view === 'list') {
        this.$refs.listView.resetComponent();
      }
      if (this.view === 'map') {
        this.listMap.filters = {
          ...this.listMap.filters,
          distance: null,
          industry_id: [],
          member_industry_id: [],
          title_id: [],
          position_id: [],
        };
        this.fetchMapList();
      }
    },
    selectMapListItem(item) {
      this.selectedMapListItem = item;
    },
    createdModalMap(map) {
      this.modalMap = map;
      map.on('zoom', () => {
        this.zoomForMap = map.getZoom();
        if (this.mapList) {
            this.mapList.setZoom(map.getZoom());
        }
      });

      map.on('load', () => {
        map.addSource(
          'polygon',
          this.mapDrawPolygon(this.location.center, this.distanceRange)
        );
        map.addLayer({
          id: 'polygon',
          type: 'fill',
          source: 'polygon',
          layout: {},
          paint: {
            'fill-color': '#ffffff',
            'fill-opacity': 0.4,
          },
        });
      });
    },
    mapDrawPolygon(center, radiusInKm, points) {
      if (!points) points = 64;

      var coords = {
        latitude: center[1],
        longitude: center[0],
      };

      var km = radiusInKm;

      var ret = [];
      var distanceX =
        km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
      var distanceY = km / 110.574;

      var theta, x, y;
      for (var i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);

      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [ret],
              },
            },
          ],
        },
      };
    },
    getTotal(val) {
      this.totalMember = val;
    },
    async resetDistance() {
      this.isOpenChangeLocation = false;
      this.isApplyDistance = false;
      this.value = 0;
      this.distance = 0;
      this.distanceRange = this.defaultDistance;
      if (this.view === 'card') {
        this.$refs.cardView._data.cursor = null;
        if (this.userRoleType === 'SELLER') {
          this.$refs.cardView._data.sellerFilterList.filter.distance = 0;
          delete this.$refs.cardView._data.sellerFilterList.filter.location;
          this.$refs.cardView.getListBySeller();
        }
        if (this.userRoleType === 'BUYER') {
          this.$refs.cardView._data.cardFilterBuyer.filter.distance = 0;
          delete this.$refs.cardView._data.cardFilterBuyer.filter.location;
          this.$refs.cardView.getList();
        }
      }

      if (this.view === 'list') {
        this.$refs.listView._data.cursor = null;
        this.$refs.listView._data.sellerFilterList.filter.distance = 0;
        delete this.$refs.listView._data.sellerFilterList.filter.location;
        if (this.userRoleType === 'SELLER') {
          this.$refs.listView.getListBySeller();
        }
        if (this.userRoleType === 'BUYER') {
          this.$refs.listView.getList();
        }
      }
      this.listMap.cursor = null;
      this.currentPage = 1;
      this.changeLocationData = {
        center: [],
        place_name: null,
      };

      delete this.listMap.filters.distance;
      delete this.listMap.filters.location;

      this.$bvModal.hide('distanceSelectModal');
      if (this.view !== 'map') {
        return;
      }
      this.mapList.flyTo({
        center: this.location.center,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
      if (this.userRoleType === 'BUYER') {
        this.fetchMapList();
      }
      if (this.userRoleType === 'SELLER') {
        await this.fetchMapListSeller();
      }
    },
    async applyDistance() {
      if (this.distanceRange > this.maxDistance && this.GET_USER_INFO.plan.upgrade == true) {
        this.upgradePlan = true;
        return;
      }

      let distance = this.calc(
        this.GET_USER_DISTANCE_UNIT,
        'km',
        this.distanceRange
      );
      if (this.mapList) {
          this.mapList.setZoom(this.modalMap.getZoom());
      }

      if (this.view === 'card') {
        this.$refs.cardView._data.cursor = null;
        if (this.userRoleType === 'SELLER') {
          this.$refs.cardView._data.sellerFilterList.filter.distance = distance;
          if (this.changeLocationData.center.length > 0) {
            this.$refs.cardView._data.sellerFilterList.filter.location = {
              lat: this.changeLocationData.center[1] || this.location.center[1],
              lon: this.changeLocationData.center[0] || this.location.center[0],
            };
          }
          this.$refs.cardView.getListBySeller();
        }
        if (this.userRoleType === 'BUYER') {
          this.$refs.cardView._data.cardFilterBuyer.filter.distance = distance;
          if (this.changeLocationData.center.length > 0) {
            this.$refs.cardView._data.cardFilterBuyer.filter.location = {
              lat: this.changeLocationData.center[1] || this.location.center[1],
              lon: this.changeLocationData.center[0] || this.location.center[0],
            };
          }
          this.$refs.cardView.getList();
        }
      }
      if (this.view === 'list') {
        this.$refs.listView._data.cursor = null;
        if (this.userRoleType === 'SELLER') {
          this.$refs.listView._data.sellerFilterList.filter.distance = distance;
          if (this.changeLocationData.center.length > 0) {
            this.$refs.listView._data.sellerFilterList.filter.location = {
              lat: this.changeLocationData.center[1] || this.location.center[1],
              lon: this.changeLocationData.center[0] || this.location.center[0],
            };
          }
          this.$refs.listView.getListBySeller();
        }
        if (this.userRoleType === 'BUYER') {
          this.$refs.listView._data.cardFilterBuyer.filter.distance = distance;
          if (this.changeLocationData.center.length > 0) {
            this.$refs.listView._data.cardFilterBuyer.filter.location = {
              lat: this.changeLocationData.center[1] || this.location.center[1],
              lon: this.changeLocationData.center[0] || this.location.center[0],
            };
          }
          this.$refs.listView.getList();
        }
      }
      this.listMap.cursor = null;
      this.currentPage = 1;
      if (this.changeLocationData.center.length > 0) {
        this.listMap.filters.location = {
          lat: this.changeLocationData.center[1],
          lon: this.changeLocationData.center[0],
        };
      } else {
          this.listMap.filters.location = {
          lat: this.location.center[1],
          lon: this.location.center[0]
        };
      }

      this.listMap.filters.distance = distance;

      if (
        this.distanceRange === 0 &&
        this.changeLocationData.center.length === 0
      ) {
        delete this.listMap.filters.distance;
        return this.resetDistance();
      }

      if (this.distanceRange === 0) {
        delete this.listMap.filters.distance;
      }
      this.isApplyDistance = true;
      this.distance = distance;
      this.$bvModal.hide('distanceSelectModal');
      if (this.mapList) {
          this.mapList.flyTo({
            center: this.changeLocationData.center,
            essential: true, // this animation is considered essential with respect to prefers-reduced-motion
          });
          this.mapList.setLayoutProperty('polygon', 'visibility', 'visible');
          this.mapList.setPaintProperty('polygon', 'fill-opacity', 0.6);
          this.mapList
            .getSource('polygon')
            .setData(
              this.mapDrawPolygon(this.changeLocationData.center, distance).data
            );

            let x = 30;
            let i = null;
            let cb = () => {
                if (x <= 0) {
                    clearInterval(i);
                    return;
                }
                
                this.mapList.setPaintProperty('polygon', 'fill-opacity', x-- / 30);
            };

            i = setInterval(cb, 75);
            setTimeout(
                () => this.mapList.setLayoutProperty('polygon', 'visibility', 'none'),
                4000
            );
      }

      //   let filter = {
      //     member_industry_id: this.filters.selectUserIndustry,
      //     industry_id: this.filters.productIndustry,
      //     title_id: this.filters.selectTitles,
      //     position_id: this.filters.selectDeparments,
      //     distance: distance || null,
      //   };

      if (this.distanceRange > 0) {
        if (this.userRoleType === 'BUYER') {
          await this.getFilterData(
              { 
                  distance, 
                  location: { 
                      lat: this.changeLocationData.center[1] || this.location.center[1], 
                      lon: this.changeLocationData.center[0] || this.location.center[0],
                  } 
             }
          );
          await this.fetchMapList();
        }
        if (this.userRoleType === 'SELLER') {
          await this.getFilterDataBySeller(
              { 
                  distance, 
                  location: { 
                      lat: this.changeLocationData.center[1] || this.location.center[1], 
                      lon: this.changeLocationData.center[0] || this.location.center[0],
                  } 
             }
          );
          await this.fetchMapListSeller();
        }
      }

      this.applyFilter();
    },
    closeDistanceModal() {
      this.openModal = false;
      this.upgradePlan = false;
    },
    draggable(val) {
      console.log(val);
    },
    changeView(par) {
      this.view = par;
    },
    openSelectDistanceModal(par) {
      this.openModal = true;
      if (this.changeLocationData.center.length === 0) {
        this.changeLocationData.center = this.location.center;
      }

      this.$bvModal.show('distanceSelectModal');
    },
    sortCustomLabel({ id, name }) {
      return `Sort by:   [${name}]`;
    },
    onPopupClose() {
      this.feature = null;
      this.mapItemList = _.map(this.mapItemList, list => _.set(list, 'top', 0));

      this.mapItemList = _.orderBy(this.mapItemList, ['_score'], ['desc']);
    },
    onSimilarsPopupClose() {
      this.similars = null;
    },
    featureClick(feature, event) {
      let item = _.find(this.mapItemList, x => x.id === feature.properties.id);
      let similars = this.similarLocations(item);
      if (_.size(similars) > 1) {
        this.similars = similars;
        return;
      }

      this.feature = feature;
      this.mapItemList = _.map(this.mapItemList, list =>
        _.merge(list, {
          top: list.id === feature.properties.id ? 1 : 0,
        })
      );

      this.mapItemList = _.orderBy(this.mapItemList, ['top'], ['desc']);
      document.querySelector('.card-list-wrapper').scrollTop = 0;
    },
    duplicateFeatureClick(feature) {
      let marker = _.find(
        this.markers,
        marker => marker.properties.id === feature.id
      );
      this.feature = marker;
      this.mapItemList = _.map(this.mapItemList, list =>
        _.merge(list, {
          top: list.id === marker.properties.id ? 1 : 0,
        })
      );

      this.mapItemList = _.orderBy(this.mapItemList, ['top'], ['desc']);
    },
    calcDistance([lat1, lon1], [lat2, lon2]) {
      let radlat1 = (Math.PI * lat1) / 180;
      let radlat2 = (Math.PI * lat2) / 180;
      let theta = lon1 - lon2;
      let radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      return dist * 1609.344;
    },
    similarLocations(item) {
      return _.filter(
        this.mapItemList,
        x =>
          this.calcDistance(
            [
              _.first(item.member.locations).point.lat,
              _.first(item.member.locations).point.lon,
            ],
            [
              _.first(x.member.locations).point.lat,
              _.first(x.member.locations).point.lon,
            ]
          ) < 5
      );
    },
    async fetchMapList() {
      this.loading = true;
      let data = await this.$apollo.query(MapList, this.listMap);
      this.markers = [];
      this.mapItemList = [];
      let response = data('**.listMap');
      this.loading = false;
      this.totalData = response.total_item || 0;
      this.totalMember = response.total_member || 0;
      this.listMap.cursor = response.next_cursor;
      let items = _.uniqBy(
        _.orderBy(response.list, ['_score'], ['desc']),
        item => item.member.id
      );
      this.mapItemList = items;
      this.mapItemList = _.map(this.mapItemList, item =>
        _.merge(item, { similar: _.size(this.similarLocations(item)) > 1 })
      );
      let markers = _.map(items, item => ({
        type: 'Feautre',
        geometry: {
          type: 'Point',
          coordinates: [
            item.member.locations.find(x => x).point.lon,
            item.member.locations.find(x => x).point.lat,
          ],
        },
        properties: {
          ...item,
          avatar: item.member.avatar
            ? item.member.avatar + '.png?map=1'
            : noAvatar,
        },
      }));

      this.markers = _.concat(this.markers, markers);
      if (!this.cursors.includes(response.next_cursor)) {
        this.cursors.push(response.next_cursor);
      }
    },
    async fetchMapListSeller() {
      this.loading = true;
      if (this.userRoleType === 'SELLER') {
        let index = this.listMap.orders.findIndex(
          item => item.field === 'price'
        );
        console.log(typeof index);
        if (index !== -1) {
          this.listMap.orders.splice(index, 1);
        }
      }

      let data = await this.$apollo.query(MapListSeller, this.listMap);
      this.markers = [];
      this.mapItemList = [];
      let response = data('**.listMap');
      this.loading = false;
      this.totalData = response.total_item || 0;
      this.totalMember = response.total_member || 0;
      this.listMap.cursor = response.next_cursor;
      let items = _.uniqBy(
        _.orderBy(response.list, ['_score'], ['desc']),
        item => item.member.id
      );
      this.mapItemList = items;
      this.mapItemList = _.map(this.mapItemList, item =>
        _.merge(item, { similar: _.size(this.similarLocations(item)) > 1 })
      );
      let markers = _.map(items, item => ({
        type: 'Feautre',
        geometry: {
          type: 'Point',
          coordinates: [
            item.member.locations.find(x => x).point.lon,
            item.member.locations.find(x => x).point.lat,
          ],
        },
        properties: {
          ...item,
          avatar: item.member.avatar
            ? item.member.avatar + '.png?map=1'
            : noAvatar,
        },
      }));
      this.markers = _.concat(this.markers, markers);

      if (!this.cursors.includes(response.next_cursor)) {
        this.cursors.push(response.next_cursor);
      }
    },
    async refreshMap() {
        await this.$nextTick();
        this.currentPage = 1;
        this.listMap.cursor = null;
        let { _ne, _sw } = this.mapList.getBounds();
        this.listMap.filters.bound.top_right.lat = _ne.lat;
        this.listMap.filters.bound.top_right.lon = _ne.lng;
        this.listMap.filters.bound.bottom_left.lon = _sw.lng;
        this.listMap.filters.bound.bottom_left.lat = _sw.lat;
        if (this.userRoleType === 'BUYER') {
          this.fetchMapList();
        }
        if (this.userRoleType === 'SELLER') {
          await this.fetchMapListSeller();
        }
    },
    async created(map) {
      this.mapList = map;
      this.refreshMap();
      map.on('load', () => {
        map.addSource(
          'polygon',
          this.mapDrawPolygon(this.location.center, this.distanceRange)
        );
        map.addLayer({
          id: 'polygon',
          type: 'fill',
          source: 'polygon',
          layout: {
            visibility: 'none',
          },
          paint: {
            'fill-color': '#ffffff',
            'fill-opacity': 0.4,
          },
        });
      });

      map.on('styleimagemissing', e => {
        map.loadImage(e.id, (error, image) => {
          if (error) {
            throw error;
          }
          if (!map.hasImage(e.id)) {
            map.addImage(e.id, image);
          }
        });
      });
      map.on('dragend', () => this.refreshMap());
      map.on('zoomend',() => this.refreshMap());
    },
    async getFilterData(filter = {}) {
      let data = await this.$apollo.query(filterDataQueryBuyer, {
        filters: filter,
      });
      let userIndustries = data('**.member_industries');
      let productIndustires = data('**.product_industries');
      let titles = data('**.titles');
      let positions = data('**.positions');

      this.fetchFilterData.userIndustries = userIndustries.map(x => ({
        id: x.id,
        label: `${x.name} (${x.count})`,
      }));

      this.fetchFilterData.productIndustires = productIndustires.map(x => ({
        id: x.id,
        label: `${x.name} (${x.count})`,
      }));
      this.fetchFilterData.titles = titles.map(x => ({
        id: x.id,
        label: `${x.name} (${x.count})`,
      }));
      this.fetchFilterData.tmp = positions.map(x => ({
        id: x.id,
        label: `${x.name} (${x.count})`,
      }));

      console.log(this.fetchFilterData.tmp);
    },
    async getFilterDataBySeller(filter = {}) {
      let data = await this.$apollo.query(filterDataQueryBuyerSeller, {
        filters: filter,
      });
      let userIndustries = data('**.member_industries');
      let productIndustires = data('**.inquiry_industries');
      let titles = data('**.titles');
      let positions = data('**.positions');
      this.fetchFilterData.userIndustries = userIndustries.map(x => ({
        id: x.id,
        label: `${x.name} (${x.count})`,
      }));

      this.fetchFilterData.productIndustires = productIndustires.map(x => ({
        id: x.id,
        label: `${x.name} (${x.count})`,
      }));

      this.fetchFilterData.titles = titles.map(x => ({
        id: x.id,
        label: `${x.name} (${x.count})`,
      }));

      this.fetchFilterData.tmp = positions.map(x => ({
        id: x.id,
        label: `${x.name} (${x.count})`,
      }));
    },
    setActiveLocation(index) {
      this.activeLocationIndex = index;
      this.$set(this.location, 'center', []);
      this.$set(this.changeLocationData, 'center', []);
      this.changeLocationData.place_name = null;
      this.location.center.push(
        this.activeLocation.point.lon,
        this.activeLocation.point.lat
      );

      this.changeLocationData.center.push(
        this.activeLocation.point.lon,
        this.activeLocation.point.lat
      );

      if (this.view === 'map') {
          this.refreshMap();
      }

      this.applyDistance();
      let distance = this.calc(
        this.GET_USER_DISTANCE_UNIT,
        'km',
        this.distanceRange
      );

      this.$nextTick(
          () => {
                if (this.userRole === 'BUYER') {
                    this.getFilterData({ distance, location: _.omit(this.activeLocation.point, ['__typename']) });
                }

                if (this.userRole === 'SELLER') {
                    this.getFilterDataBySeller({ distance, location: _.omit(this.activeLocation.point, ['__typename']) });
                }
          }
      );
    },
    treeSelectOpen(instanceId) {
    //   let selects = ['ts-pi', 'ts-ui', 'ts-st', 'ts-d'];
    //   for (let select of selects) {
    //     if (this.$refs[select].instanceId !== instanceId) {
    //       this.$refs[select].closeMenu();
    //     }
    //   }
      // console.log(this.$refs.ts2.closeMenu());
    },
    changeRoleType(type) {
      window.localStorage.setItem('user_type', type);
      location.reload();
    },
    keepLocation() {
        localStorage.setItem(
            'dashboard:location',
            JSON.stringify(
                {
                    activeLocationIndex: this.activeLocationIndex,
                    distanceRange: this.distanceRange,
                    distance: this.distance,
                    location: this.location,
                    changeLocationData: this.changeLocationData
                }
            )
        );
    },
    loadLocationFromSaved() {
        let before = localStorage.getItem('dashboard:location');
        if (!_.isNil(before)) {
            before = JSON.parse(before);
            if (!_.isNil(before.activeLocationIndex)) {
                this.activeLocationIndex = before.activeLocationIndex;   
            }

            if (!_.isNil(before.distanceRange)) {
                this.distanceRange = before.distanceRange;   
            }

            if (!_.isNil(before.distance)) {
                this.distance = before.distance;
                this.listMap.filters.distance = before.distance;   
            }

            if (!_.isNil(before.location)) {
                this.location = before.location;   
            }

            if (!_.isNil(before.changeLocationData)) {
                this.changeLocationData = before.changeLocationData;   
            }
        }
    },
    doSearch(clear) {
      this.distance = this.calc(this.GET_USER_DISTANCE_UNIT, 'km', this.distanceRange);
      if (clear === true) {
          this.search = null;
          this.searched = false;
      }

      if (this.view === 'list') {
        this.$refs.listView._data.cursor = null;
        this.$refs.listView._data.cursors = [];
        this.$refs.listView._data.currentPage = 1;
        if (this.userRoleType === 'BUYER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
            search: this.search || null,
            location: {
                lon: this.changeLocationData.center[0] || this.location.center[0],
                lat: this.changeLocationData.center[1] || this.location.center[1]
            }
          };
          this.$refs.listView._data.cardFilterBuyer.filter = filter;
          this.$refs.listView.getList();
        }
        if (this.userRoleType === 'SELLER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
            search: this.search || null,
            location: {
                lon: this.changeLocationData.center[0] || this.location.center[0],
                lat: this.changeLocationData.center[1] || this.location.center[1]
            }
          };
          this.$refs.listView._data.sellerFilterList.filter = filter;
          this.$refs.listView.getListBySeller();
        }
      }
      if (this.view === 'card') {
        this.$refs.cardView._data.cursor = null;
        this.$refs.cardView._data.cursors = [];
        this.$refs.cardView._data.currentPage = 1;
        if (this.userRoleType === 'BUYER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
            search: this.search || null,
            location: {
                lon: this.changeLocationData.center[0] || this.location.center[0],
                lat: this.changeLocationData.center[1] || this.location.center[1]
            }
          };
          this.$refs.cardView._data.cardFilterBuyer.filter = filter;
          this.$refs.cardView.getList();
        }
        if (this.userRoleType === 'SELLER') {
          let filter = {
            excludeds: this.excludedItems,
            member_industry_id: this.filters.selectUserIndustry,
            industry_id: this.filters.productIndustry,
            title_id: this.filters.selectTitles,
            position_id: this.filters.selectDeparments,
            distance: this.distance || null,
            search: this.search || null,
            location: {
                lon: this.changeLocationData.center[0] || this.location.center[0],
                lat: this.changeLocationData.center[1] || this.location.center[1]
            }
          };
          this.$refs.cardView._data.sellerFilterList.filter = filter;
          this.$refs.cardView.getListBySeller();
        }
      }
      if (this.view === 'map') {
        this.listMap.cursor = null;
        this.currentPage = 1;
        let industry_id = this.filters.productIndustry;
        let position_id = this.filters.selectDeparments;
        let title_id = this.filters.selectTitles;
        let member_industry_id = this.filters.selectUserIndustry;
        this.listMap.filters = {
          ...this.listMap.filters,
          excludeds: this.excludedItems,
          location: {
            lon: this.changeLocationData.center[0] || this.location.center[0],
            lat: this.changeLocationData.center[1] || this.location.center[1]
          },
          distance: this.distance,
          industry_id,
          member_industry_id,
          title_id,
          position_id,
          search: this.search || null,
        };
        
        if (this.userRoleType === 'BUYER') {
          this.fetchMapList();
        }
        if (this.userRoleType === 'SELLER') {
          this.fetchMapListSeller();
        }
      }

      if (this.userRoleType === 'BUYER') {
          this.getFilterData({ search: this.search || null });
        }
        if (this.userRoleType === 'SELLER') {
          this.getFilterDataBySeller({ search: this.search || null });
        }
    },
    async loadMProducts() {
         let cursor = null;
         while (true) {
             let selector = await this.$apollo.query(LOAD_MY_PRODUCTS, { limit: 100, filters: { active: true }, cursor });
             if (_.size(selector('**.list.list')) === 0) {
                 break;
             }

             this.mproducts.push(...selector('**.list.list'));
             cursor = selector('**.next_cursor');
         }
    },
    async loadMInquiries() {
        let cursor = null;
         while (true) {
             let selector = await this.$apollo.query(LOAD_MY_INQUIRIES, { limit: 100, filters: { active: true }, cursor });
             if (_.size(selector('**.list.list')) === 0) {
                 break;
             }

             this.minquiries.push(...selector('**.list.list'));
             cursor = selector('**.next_cursor');
         }
    }
  },
  mounted() {

  },
  async created() {
    this.location.center = [];
    this.location.center.push(
      this.activeLocation.point.lon,
      this.activeLocation.point.lat
    );

    this.view = localStorage.getItem('activeView') || 'map';
    this.distance = this.GET_USER_INFO.group.permissions.default_distance;
    this.listMap.filters.distance = this.GET_USER_INFO.group.permissions.default_distance;
    this.distanceRange = this.GET_USER_INFO.group.permissions.default_distance;
    this.loadLocationFromSaved();
    this.$watch('$data.activeLocationIndex', () => this.keepLocation(), { deep: true });
    this.$watch('$data.distanceRange', () => this.keepLocation(), { deep: true });
    this.$watch('$data.distance', () => this.keepLocation(), { deep: true });
    this.$watch('$data.location', () => this.keepLocation(), { deep: true });
    this.$watch('$data.changeLocationData', () => this.keepLocation(), { deep: true });
    if (this.userRoleType === 'BUYER') {
      this.getFilterData({ distance: this.distance });
    }
    if (this.userRoleType === 'SELLER') {
      this.getFilterDataBySeller({ distance: this.distance });
    }
    if (this.userRoleType === 'BUYER') {
      this.loadMInquiries();
    }
    if (this.userRoleType === 'SELLER') {
      this.loadMProducts();
    }
  },
};
</script>
