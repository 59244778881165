<template>
  <div class="card-modal-view">
    <div @click="close" class="close-icon">
      <v-icon :icon="['fac', 'close']" />
    </div>
    <div @click="next" class="button-right" :class="{ disabled: disabledNext }">
      <v-icon icon="chevron-right" />
    </div>
    <div @click="prev" class="button-left" :class="{ disabled: disabledPrev }">
      <v-icon icon="chevron-left" />
    </div>
    <div class="card-product-modal">
      <div class="card-product-modal-view">
        <div
          v-if="isLoading"
          class="card py-5 d-flex align-items-center justify-content-center"
          
        >
          <loading :loading="isLoading" />
        </div>
        <div
          v-if="isLoading == false && userRoleType === 'BUYER'"
          class="d-flex zoom"
        >
          <div class="left" id="card-modal-view__left">
            <b-carousel
              id="carousel-1"
              :interval="4000"
              indicators
              controls
              background="#ffff"
              style="text-shadow: 1px 1px 2px #333"
            >
              <!-- Text slides with image -->
              <b-carousel-slide
                v-for="(img, index) in selectItem.images"
                :key="index"
                style="margin-top: 18px"
                class="card-view-carousel-img"
                :img-src="
                  CDN_IMAGE_PREFIX + img + '?size=315xauto'
                "
              ></b-carousel-slide>
            </b-carousel>
            <div class="card-product-modal-view-product-content">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h3 class="product-name">
                    {{ findSubItemByLang(selectItem.contents).name }}
                  </h3>

                  <div class="product-id">{{$t("mw.general.id2")}}: {{ selectItem.product_id }}</div>
                </div>
                <div
                  class="map-list-industry-icon position-relative"
                  style="top: 0; right: 0"
                >
                  <industry-icon
                    :color="'primary'"
                    :name="item.industry.icon"
                  />
                </div>
              </div>
              <div class="item-info-content">
                <div class="item">
                  <span class="key">{{$t("mw.general.price")}}</span>
                  <span class="value"></span>
                
                   {{ selectItem.currency.symbol }} {{ selectItem.min_price }} - {{ selectItem.currency.symbol }} {{ selectItem.max_price }}  <span class="flag" style="margin-left:5px;"> / {{ selectItem.unit.name }}</span>
                </div>
                <div class="item">
                  <span class="key">{{$t("mw.general.moq")}}</span>
                  <span class="value">{{ numberFixer(item.moq) }}</span>
                  <span class="flag" style="margin-left:5px;">  {{ selectItem.unit.name }}</span>
                  
                </div>
                <div class="item">
                  <span class="key"> {{$t("mw.general.supply")}} </span>
                  <span class="value" > {{ numberFixer (selectItem.stock) }} </span>
                  <span class="flag" style="margin-left:5px;">  {{ selectItem.unit.name }} / {{$t("mw.general.monthly")}}</span>
                </div>
              </div>
              <hr>
              <div class="product-desc-inmodal">
                <!--<p>
                  {{ getDefault(selectItem.contents).description }}
                </p> -->
                <nl2br tag="p" :text="findSubItemByLang(selectItem.contents).description" />
              </div>
            </div>
          </div>
          <div class="right" style="overflow:scroll; overflow-x:hidden">
            <div class="info-card">
              <!-- <img
                class="md-img"
                :src="getAvatar(selectItem.member.avatar, 83)"
                alt=""
              /> -->
              <b-avatar
                :src="getAvatar(selectItem.member.avatar, 83)"
                size="83"
                class="right-img-modal-card mr-2"
              ></b-avatar>

              <div class="left-img-modal-card">
                <p class="name">
                  <a
                    href="#"
                    @click.prevent="getUserProfile(selectItem.member.uid)"
                  >
                    <strong
                      >{{ shorter( selectItem.member.name,selectItem.member.surname) }}</strong
                    >
                    <img
                       v-if="$_.get(selectItem, 'member.verification.icon')"
                       :src="$_.get(selectItem, 'member.verification.icon')"
                      class="img-fluid"
                      alt=""
                      style="height: 14px; width: 14px; margin-left: 10px"
                    />
                  </a>
                </p>
                <p class="info-title">
                  {{ selectItem.member.title.name }}
                </p>
              </div>
            </div>
             <div class="social-links" style="margin-top:0px">
                            <b-row>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['LINKEDIN']"
                                >
                                    <a :href="userSocialLinks['LINKEDIN']['link']" target="_blank">
                                    <img
                                        src="/img/card-linkedin-map.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                    
                                    <img
                                        src="/img/card-linkedin-map.svg"
                                        class="img-fluid social-icon-disabled"
                                        alt=""
                                    />
                                   
                                </div>
                                </b-col>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['FACEBOOK']"
                                >
                                    <a :href="userSocialLinks['FACEBOOK']['link']" target="_blank">
                                    <img
                                        src="/img/facebook-icon.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                
                                    
                                    <img
                                        src="/img/facebook-icon.svg"
                                        class="img-fluid social-icon-disabled"
                                        size="50"
                                        alt="facebook"
                                    />

                                    
                                    
                                 
                                </div>
                                </b-col>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['TWITTER']"
                                >
                                    <a :href="userSocialLinks['TWITTER']['link']" target="_blank">
                                    <img
                                        src="/img/twitter-icon.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                    
                                    <img
                                        src="/img/twitter-icon.svg"
                                        class="img-fluid social-icon-disabled"
                                        alt=""
                                    />
                                   
                                </div>
                                </b-col>
                            </b-row>
                       </div>

                       <div class="map-popup-seperator-div">
                      
                       </div>
            <div v-if="shortMessage" class="row short-message-send">
              <div class="col-md-12 mb-2">
              
                <b-form-textarea
                  id="textarea"
                  v-model="popUpShortMessage2"
                  rows="27"
                ></b-form-textarea>
              </div>
              <div class="col-md-6">
                <a @click="shortMessage = false" class="cancel-short-message">{{$t("buttons.cancel")}}</a>
              </div>
              <div class="col-md-6 text-right">
                <button
                  class="btn btn-primary text-right short-message-send-button"
                  @click="popUpSendShortMessage2(selectItem.member.uid)"
                >
                  {{$t("buttons.send")}}
                </button>
              </div>

              
            </div>
            <div v-if="!shortMessage" class="">
              <div class="info-item">
                 <p class="title mb-2">{{$t("profile.contents.myprofile.aboutme.location")}}</p>
                <div class="title mt-4" style="">
                  <v-icon
                    style="margin-right: 7px !important"
                    :icon="['fac', 'location']"
                  />
                  <span
                    >{{
                      selectItem.member.locations.find((x) => x).address.city || 'unnamed'
                    }},{{
                      selectItem.member.locations.find((x) => x).address.country
                    }}</span
                  >
                </div>
              </div>
              <div class="info-item mt-4">
                <p class="title mb-2">{{$t("mw.general.industry")}}</p>
                <!-- <span class="info-industry-icon">
                  <v-icon :icon="['fac', 'user']" />
                </span> -->
                <span class="industry-name value">{{
                  selectItem.member.industry.name
                }}</span>
              </div>

              <div class="info-item mt-4">
                <p class="title mb-2">{{$t("mw.general.company2")}}</p>
                <!-- <span class="info-industry-icon">
                  <v-icon :icon="['fac', 'user']" />
                </span> -->
                <span class="industry-name value">
                  {{selectItem.member.company.name}}
                  </span>
              </div>

            

              <div v-if="selectItem.member.position" class="info-item mt-4">
                <p class="title mb-2">{{$t("mw.general.position")}}</p>
                <p class="value">{{ selectItem.member.position.name }}</p>
              </div>

              <div class="info-item mt-4">
                <p class="title mb-2">{{$t("mw.general.experience")}}</p>
                <p class="value">
                  {{ selectItem.member.years_of_experience.low }} +
                </p>
              </div>
              <div class="info-item mt-4">
                <p class="title mb-2">{{$t("mw.general.about")}}</p>
                <nl2br tag="p" className="value modal-about-us" :text="selectItem.member.about" />
              </div>
            </div>
           
          </div>
           <div class="info-props">
              <!--<div class="props-img m-0 position-relative">
                <img :src="getAvatar(selectItem.member.avatar, 46)" alt="" />
                <div
                  v-if="!selectItem.member.following"
                  @click.prevent="followUser"
                  class="modal-follow-icon"
                >
                  <v-icon icon="plus-circle" />
                </div>
                <div
                  v-if="selectItem.member.following"
                  @click.prevent="unfollowUser"
                  class="modal-follow-icon unf"
                >
                  <v-icon icon="minus-circle" />
                </div>
              </div>
              <p v-if="!selectItem.member.following" class="mb-5 mt-1 p-0">
                Follow
              </p>
              <p v-if="selectItem.member.following" class="mb-5 mt-1 p-0">
                Unfollow
              </p> -->

              <div class="text-center follow-unfollow">
                <!--<v-icon icon="question-circle"></v-icon> -->
                <img
                  :src="getAvatar(selectItem.member.avatar, 46)"
                  class="img-fluid"
                  alt=""
                  style="width: 48px; height: 48px; border-radius: 50%"
                />
                <div
                  v-if="!selectItem.member.following"
                  @click.prevent="followUser"
                  class="modal-follow-icon"
                >
                  <v-icon icon="plus-circle" />
                </div>
                <div
                  v-if="selectItem.member.following"
                  @click.prevent="unfollowUser"
                  class="modal-follow-icon unf"
                >
                  <v-icon icon="minus-circle" />
                </div>
                <p v-if="!selectItem.member.following" class="mt-1 p-0">
                  {{$t("buttons.follow")}}
                </p>
                <p v-if="selectItem.member.following" class="mt-1 p-0">
                   {{$t("buttons.unfollow")}}
                </p>
              </div>

              <div
                class="text-center quick-request"
                @click="shortMessage = !shortMessage"
              >
                <!--<v-icon icon="question-circle"></v-icon> -->
                <img
                  src="/img/quick-request-icon.svg"
                  class="img-fluid"
                  alt=""
                  style="width: 48px; height: 48px"
                  @click="shortMessage === !shortMessage"
                />
                <p> {{$t("buttons.q-message")}}</p>
              </div>

              <div
                class="text-center modal-chat-div"
                @click="startConversation(selectItem.member.uid)"
              >
                <!--<v-icon icon="question-circle"></v-icon> -->
                <!--@click="startConversation(selectItem.member.uid)" -->
                <img
                  src="/img/chat-icon-modal.svg"
                  class="img-fluid"
                  alt=""
                  style="width: 48px; height: 48px"
                  @click="startConversation(selectItem.member.uid)"
                />
                <p> {{$t("buttons.chat")}}</p>
              </div>

               <div
                class="text-center modal-chat-div"
                 @click.prevent="getUserProfile(selectItem.member.uid)"
              >
                <!--<v-icon icon="question-circle"></v-icon> -->
                <!--@click="startConversation(selectItem.member.uid)" -->
                 
                  
                <img
                  src="/img/go-to-profile.svg"
                  class="img-fluid"
                  alt=""
                  style="width: 48px; height: 48px"
                  
                />
                
                <p> {{$t("buttons.profile")}}</p>
              </div>
              
              <!-- <div
                class="props-chat cursor-pointer"
                @click="startConversation(selectItem.member.uid)"
              >
                <div class="icon">
                  <v-icon :icon="['far', 'comment-alt']"></v-icon>
                </div>
                <p>Chat</p>
              </div> -->
            </div>
        </div>
        <div
          v-if="isLoading == false && userRoleType === 'SELLER'"
          class="d-flex zoom"
        >
          <div class="left popup-buyer-left" id="card-modal-view__left">
            <div class="popup-buyer-left-head d-flex">
              <div class="industry-icon">
                <!-- <industry-icon
                  :color="'gray'"
                  :name="selectItem.industry.icon"
                /> -->
                <industry-icon
                  :color="'primary'"
                  :name="selectItem.industry.icon"
                />
              </div>

              <div>
                <h3><span style="text-transform:capitalize">{{ findSubItemByLang(selectItem.contents).name }}</span></h3>
                <div class="item-info">
                  <div class="item d-flex align-items-center">
                    <p class="title"> {{$t("mw.general.unit")}}</p>
                    <p >
                      {{ selectItem.unit.name }}
                    </p>
                  </div>
                  <div class="item d-flex align-items-center">
                    <p class="title">{{$t("mw.general.demand")}}</p>
                    <p class="value">{{ numberFixer(selectItem.demand) }}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div class="item-desc buyer-card-item-desc-modal pt-2">
              <h6
                style="color: #2c353f; font-size: 14px; font-weight: 300"
                class="mb-3 mt-2"
              >
                {{$t("mw.general.r-def")}}
              </h6>
              <nl2br tag="p" :text="findSubItemByLang(selectItem.contents).description"/>
            </div>

            <div class="row buyer-card-modal-industry">
              <div class="col-md-2">
                <industry-icon
                  :color="'primary'"
                  :name="selectItem.industry.icon"
                />
              </div>
              <div class="col-md-10 pt-1">
                <h5>{{ selectItem.industry.name }}</h5>
              </div>
            </div>
          </div>
      
          <div class="right">
            
            <div class="info-card">
              <img
                class="md-img mr-2"
                :src="getAvatar(selectItem.member.avatar, 83)"
                width="83"
                height="83"
                alt=""
              />
              <div style="margin-left: 18px">
                <a
                  href="#"
                  @click.prevent="getUserProfile(selectItem.member.uid)"
                  class="name"
                >
                  <strong
                    > {{ shorter(selectItem.member.name, selectItem.member.surname) }}
                  </strong>
                  <img
                       v-if="$_.get(selectItem, 'member.verification.icon')"
                       :src="$_.get(selectItem, 'member.verification.icon')"
                      class="img-fluid"
                      alt=""
                      style="height: 14px; width: 14px; margin-left: 10px"
                    />
                </a>
                <p class="info-title">
                  {{ selectItem.member.title.name }}
                </p>
              </div>
              
            </div>
            <div class="social-links" style="margin-top:0px">
                <b-row>
                    <b-col>
                    <div
                        class="user-detail-linked-icons"
                        v-if="userSocialLinks['LINKEDIN']"
                    >
                        <a :href="userSocialLinks['LINKEDIN']['link']" target="_blank">
                        <img
                            src="/img/card-linkedin-map.svg"
                            class="img-fluid social-icon"
                            alt=""
                        />
                        </a>
                    </div>
                    <div
                        class="user-detail-linked-icons"
                        v-else
                    >
                        
                        <img
                            src="/img/card-linkedin-map.svg"
                            class="img-fluid social-icon-disabled"
                            alt=""
                        />
                        
                    </div>
                    </b-col>
                    <b-col>
                    <div
                        class="user-detail-linked-icons"
                        v-if="userSocialLinks['FACEBOOK']"
                    >
                        <a :href="userSocialLinks['FACEBOOK']['link']" target="_blank">
                        <img
                            src="/img/facebook-icon.svg"
                            class="img-fluid social-icon"
                            alt=""
                        />
                        </a>
                    </div>
                    <div
                        class="user-detail-linked-icons"
                        v-else
                    >
                        
                        <img
                            src="/img/facebook-icon.svg"
                            class="img-fluid social-icon-disabled"
                            size="50"
                            alt="facebook"
                        />
                        
                    </div>
                    </b-col>
                    <b-col>
                    <div
                        class="user-detail-linked-icons"
                        v-if="userSocialLinks['TWITTER']"
                    >
                        <a :href="userSocialLinks['TWITTER']['link']" target="_blank">
                        <img
                            src="/img/twitter-icon.svg"
                            class="img-fluid social-icon"
                            alt=""
                        />
                        </a>
                    </div>
                    <div
                        class="user-detail-linked-icons"
                        v-else
                    >
                        
                        <img
                            src="/img/twitter-icon.svg"
                            class="img-fluid social-icon-disabled"
                            alt=""
                        />
                        
                    </div>
                    </b-col>
                </b-row>
            </div>

            <div class="map-popup-seperator-div">
            </div>
                    
            <div v-if="shortMessage" class="row short-message-send">
              <div class="col-md-12 mb-2">
           
                <b-form-textarea
                  id="textarea"
                  v-model="popUpShortMessage"
                  rows="27"
                ></b-form-textarea>
              </div>
              <div class="col-md-6">
                <a @click="shortMessage = false" class="cancel-short-message">{{$t("buttons.cancel")}}</a>
              </div>
              <div class="col-md-6 text-right">
                <button
                  class="btn btn-primary text-right short-message-send-button"
                  @click="popUpSendShortMessage(selectItem.member.uid)"
                >
                  {{$t("buttons.send")}}
                </button>
              </div>
            </div>
           
            <div
              class="info-item-content buyer-modal-content"
              v-if="!shortMessage"
            >
              <div class="info-item">
                 <p class="title mb-2">{{$t("profile.contents.myprofile.aboutme.location")}}</p>
                <div class="title mt-3">
                  <v-icon :icon="['fac', 'location']" />
                  <span style="margin-left:20px;"
                    > {{
                      selectItem.member.locations
                        ? selectItem.member.locations.find((x) => x).address
                            .city
                        : "-"
                    }},{{
                      selectItem.member.locations
                        ? selectItem.member.locations.find((x) => x).address
                            .country
                        : "-"
                    }}</span
                  >
                 
                </div>
                 
              </div>
              <div class="info-item mt-4">
                <p class="title mb-2">{{$t("mw.general.industry")}}</p>
                <span class="value">{{
                  selectItem.member.industry.name
                }}</span>
              </div>
              <div class="info-item mt-4">
                <p class="title mb-2">{{$t("mw.general.company2")}}</p>
                <div class="value">{{ selectItem.member.company.name }}</div>
              </div>

              <div v-if="selectItem.member.position" class="info-item mt-4">
                <p class="title mb-2">{{$t("mw.general.position")}}</p>
                <p class="value">{{ selectItem.member.position.name }}</p>
              </div>

              <div class="info-item mt-4">
                <p class="title mb-2">{{$t("mw.general.experience")}}</p>
                <p class="value">
                  {{ selectItem.member.years_of_experience.low }}+
                </p>
              </div>
              <div class="info-item mt-4">
                <p class="title mb-2">{{$t("mw.general.about")}}</p>
                <p class="value">
                  {{ selectItem.member.about }}
                </p>
              </div>
            </div>
            
            
          </div>
          <div class="info-props">
              <!-- <div class="props-img m-0 position-relative">
                <img :src="getAvatar(selectItem.member.avatar, 46)" alt="" />
                <div
                  v-if="!selectItem.member.following"
                  @click.prevent="followUser"
                  class="modal-follow-icon"
                >
                  <v-icon icon="plus-circle" />
                </div>
                <div
                  v-if="selectItem.member.following"
                  @click.prevent="unfollowUser"
                  class="modal-follow-icon unf"
                >
                  <v-icon icon="minus-circle" />
                </div>
              </div>
              <p v-if="!selectItem.member.following" class="mb-5 mt-1 p-0">
                Follow
              </p>
              <p v-if="selectItem.member.following" class="mb-5 mt-1 p-0">
                Unfollow
              </p> -->
              <div class="text-center follow-unfollow">
                <!--<v-icon icon="question-circle"></v-icon> -->
                <img
                  :src="getAvatar(selectItem.member.avatar, 46)"
                  class="img-fluid"
                  alt=""
                  style="width: 48px; height: 48px; border-radius: 50%"
                />
                <div
                  v-if="!selectItem.member.following"
                  @click.prevent="followUser"
                  class="modal-follow-icon"
                >
                  <v-icon icon="plus-circle" />
                </div>
                <div
                  v-if="selectItem.member.following"
                  @click.prevent="unfollowUser"
                  class="modal-follow-icon unf"
                >
                  <v-icon icon="minus-circle" />
                </div>
                <p v-if="!selectItem.member.following" class="mt-1 p-0">
                  {{$t("buttons.follow")}}
                </p>

                <p v-if="selectItem.member.following" class="mt-1 p-0">
                  {{$t("buttons.unfollow")}}
                </p>
              </div>
              <!-- <div class="props-ask">
                <div class="icon">
                  <v-icon icon="question-circle"></v-icon>
                </div>
                <p>Ask1</p>
              </div> -->

              <div
                class="text-center quick-request"
                @click="shortMessage = !shortMessage"
              >
                <!--<v-icon icon="question-circle"></v-icon> -->
                <img
                  src="/img/quick-request-icon.svg"
                  class="img-fluid"
                  alt=""
                  style="width: 48px; height: 48px"
                  @click="shortMessage === !shortMessage"
                />
                <p>{{$t("buttons.q-message")}}</p>
              </div>

              <!--<div
                class="props-chat cursor-pointer"
                @click="startConversation(selectItem.member.uid)"
              >
                <div
                  class="icon cursor-pointer"
                  @click.prevent="startConversation(selectItem.member.uid)"
                >
                  <v-icon :icon="['fac', 'message']"></v-icon>
                </div>
                <p>Chat</p>
              </div> -->
              <div
                class="text-center modal-chat-div"
                @click="startConversation(selectItem.member.uid)"
              >
                <!--<v-icon icon="question-circle"></v-icon> -->
                <!--@click="startConversation(selectItem.member.uid)" -->
                <img
                  src="/img/chat-icon-modal.svg"
                  class="img-fluid"
                  alt=""
                  style="width: 48px; height: 48px"
                  @click="startConversation(selectItem.member.uid)"
                />
                <p>{{$t("buttons.chat")}}</p>
              </div>

             <div
                class="text-center modal-chat-div"
                 @click.prevent="getUserProfile(selectItem.member.uid)"
              >
                <!--<v-icon icon="question-circle"></v-icon> -->
                <!--@click="startConversation(selectItem.member.uid)" -->
                 
                  
                <img
                  src="/img/go-to-profile.svg"
                  class="img-fluid"
                  alt=""
                  style="width: 48px; height: 48px"
                  
                />
                
                <p> {{$t("buttons.profile")}}</p>
              </div>
            </div>
         
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import industryIcon from "../../industryIcon.vue";
import followUser from "@/graphql/me/profile/follow.graphql";
import unFollowUser from "@/graphql/me/profile/unFollow.graphql";
import _ from 'lodash';

export default {
  components: { industryIcon },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userMeInfo: [],
      selectItem: null,
      isLoading: false,
      disabledNext: false,
      disabledPrev: false,
      itemIndex: 0,
      popUpShortMessage: null,
      popUpShortMessage2: null,
      shortMessage: false,
      buyerModal: true,
    };
  },
   computed: {
    userSocialLinks() {
      let accounts = _.get(this, "item.member.social_accounts", []);
      let filtered = _.filter(accounts, (sa) => !_.isNil(sa.link));
      return _.keyBy(filtered, "type");
    },
  },
  methods: {
    async popUpSendShortMessage(uid) {
      let par = {
        to_uid: uid,
      };
      let messengerId = await this.$store.dispatch(
        "messenger/getConversationStart",
        { vm: this, par }
      );

      let body = {
        conversation_id: messengerId,
        content: {
          message: this.popUpShortMessage,
          refs: [{ type: "INQUIRY", id: this.selectItem.id }],
        },
      };

      this.$io.emit("message", body, () =>
        this.$router.push(`/app/messenger/${messengerId}`)
      );
    },

    async popUpSendShortMessage2(uid) {
      let par = {
        to_uid: uid,
      };
      let messengerId = await this.$store.dispatch(
        "messenger/getConversationStart",
        { vm: this, par }
      );

      let body = {
        conversation_id: messengerId,
        content: {
          message: this.popUpShortMessage2,
          refs: [{ type: "PRODUCT", id: this.selectItem.id }],
        },
      };

      this.$io.emit("message", body, () =>
        this.$router.push(`/app/messenger/${messengerId}`)
      );
    },

    shortMessageContent() {
      this.popUpShortMessage =
        "Dear " +
        this.selectItem.member.name +
        ", \nThis is " +
        this.userMeInfo.name +
        " " +
        this.userMeInfo.surname +
        " from " +
        this.userMeInfo.company.name +
        ". I am contacting for your " +
        this.findSubItemByLang(this.selectItem.contents).name +
        " request. What would be the best time for you to discuss the details ? \n \n Kind ragards, \n \n" +
        this.userMeInfo.name 
        ;
    },

    shortMessageContent2() {
      this.popUpShortMessage2 =
        "Dear " +
        this.selectItem.member.name +
        ", \nThis is " +
        this.userMeInfo.name +
        " " +
        this.userMeInfo.surname +
        " from " +
        this.userMeInfo.company.name +
        ". I am interested in your " +
        this.findSubItemByLang(this.selectItem.contents).name +
        " quotation with an ID of " +
        this.selectItem.product_id +
        ". Please contact me at your earliest convenience. \n \n Kind ragards, \n \n" +
        this.userMeInfo.name 
        ;
    },

    async followUser() {
      console.log(this.selectItem);
      let data = await this.$apollo.mutate(followUser, {
        uid: this.selectItem.member.uid,
      });
      let response = data("**.follow");
      if (response) {
        this.selectItem.member.following = response;
        this.$emit("follow", { id: this.selectItem.member.id, status: true });
      }
    },
    async unfollowUser() {
      let data = await this.$apollo.mutate(unFollowUser, {
        uid: this.selectItem.member.uid,
      });
      let response = data("**.unfollow");
      if (response) {
        this.selectItem.member.following = !response;
        this.$emit("follow", { id: this.selectItem.member.id, status: false });
      }
    },
    next() {
      const cardModalViewLeft = document.getElementById("card-modal-view__left");
      cardModalViewLeft.scrollTo(0,0);
      setTimeout(
          ()=> { 
            this.$emit("next", this.itemIndex);
            this.$nextTick(
                () => {
                    this.shortMessageContent();
                    this.shortMessageContent2();
                }
            );
          }, 
          200
      );
    },
    prev() {
      const cardModalViewLeft = document.getElementById("card-modal-view__left");
      cardModalViewLeft.scrollTo(0,0);
      setTimeout(
          ()=> { 
              this.$emit("prev", this.itemIndex === 0 ? 0 : --this.itemIndex);
              this.$nextTick(
                () => {
                    this.shortMessageContent();
                    this.shortMessageContent2();
                }
              );
          }, 
          200
      );
    },
    close() {
      this.$emit("close");
    },
  },
  created() {
    this.selectItem = this.item;
    let userInfo = this.GET_USER_INFO;
    this.userMeInfo = userInfo;
    this.shortMessageContent();
    this.shortMessageContent2();
  }
};
</script>
